import storage from "services/storage";
import { sendRequest } from "util/utils";

const getSurveysList = async ({
    page,
    search,
    areaManagement,
    workArea,
    undersecretary,
}) => {
    const token = `Bearer ${storage.getToken()}`,
        route = `/surveys/procedures?page=${page}&search=${search}&area_management=${areaManagement}&work_area=${workArea}&undersecretary=${undersecretary}`,
        request = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: token,
                token: storage.getSSID(),
            },
        };
    return await sendRequest(route, request);
};



const getOverallResults = async (procedureID, init, end) => {
    const token = `Bearer ${storage.getToken()}`,
        route = `/surveys/procedures/global_results/${procedureID}?date_init=${init}&end_date=${end}`,
        request = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: token,
                token: storage.getSSID(),
            },
        };
    return await sendRequest(route, request);
};
const getIndividualResultsList = async (procedureID, search, page) => {
    const token = `Bearer ${storage.getToken()}`,
        route = `/surveys/procedures/individual_results/${procedureID}?page=${page}&search=${search}`,
        request = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: token,
                token: storage.getSSID(),
            },
        };
    return await sendRequest(route, request);
};
const getIndividualAnswers = async (surveyID) => {
    const token = `Bearer ${storage.getToken()}`,
        route = `/surveys/procedures/individual_results/user_answers/${surveyID}`,
        request = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: token,
                token: storage.getSSID(),
            },
        };
    return await sendRequest(route, request);
};


const sendAnswersSurveyProcedure = async (survey_id, data) => {
    const token = `Bearer ${storage.getToken()}`,
        route = `/surveys/procedures/${survey_id}`,
        request = {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: token,
                token: storage.getSSID(),
                "Content-Type": "application/json",
            },
            body: data,
        };
    return await sendRequest(route, request);
};

const getProcedureInfo = async (procedureRequestID) => {
    const token = `Bearer ${storage.getToken()}`,
        route = `/surveys/procedures_requests/data/${procedureRequestID}`,
        request = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: token,
                token: storage.getSSID(),
            },
        };
    return await sendRequest(route, request);
};

const downloadSurveyReportProcedure = async ({
    format,
    search,
    areaManagement,
    workArea,
    undersecretary
}) => {
    const token = `Bearer ${storage.getToken()}`,
        route = `/surveys/procedures/global/report?format=${format}&search=${search}&area_management=${areaManagement}&work_area=${workArea}&undersecretary=${undersecretary}`,
        request = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: token,
                token: storage.getSSID(),
            },
        };
    return await sendRequest(route, request, true);
};
const downloadSurveyReportIndividual = async ({ procedureID, format }) => {
    const token = `Bearer ${storage.getToken()}`,
        route = `/surveys/procedures/report/${procedureID}?format=${format}`,
        request = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: token,
                token: storage.getSSID(),
            },
        };
    return await sendRequest(route, request, true);
};

export default {
    getSurveysList,
    getOverallResults,
    getIndividualResultsList,
    getIndividualAnswers,
    sendAnswersSurveyProcedure,
    getProcedureInfo,
    downloadSurveyReportProcedure,
    downloadSurveyReportIndividual
};