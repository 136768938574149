import storage from "services/storage";
import { sendRequest } from "util/utils";

const getAnnouncements = async () => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/announcements/statistics`,
    request = {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const getAnnouncementDoc = async announcementId => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/announcements/document/${announcementId}`,
    request = {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const updateFiles = async (data, announcement_id) => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/announcements/${announcement_id}`;
  const request = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: token,
      token: storage.getSSID(),
    },
    body: data
  };
  return await sendRequest(route, request);
};

const replaceDoc = async (data, announcement_id) => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/announcements/${announcement_id}/sequence`;
  const request = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: token,
      token: storage.getSSID(),
    },
    body: data
  };
  return await sendRequest(route, request);
};

export default {
  getAnnouncements,
  getAnnouncementDoc,
  updateFiles,
  replaceDoc
};