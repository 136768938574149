import {
    HANDLE_SURVEY_PROCEDURE_MODAL,
    HANDLE_SURVEY_ANNOUNCEMENT_MODAL,
    HANDLE_SURVEY_RECORD_MODAL
} from "../constants/ActionTypes";


const initialState = {
    surveyProcedureModal: { open: false, id: null, mode: '' },
    surveyAnnouncementModal: { open: false, id: null },
    surveyRecordModal: {open: false, }
}

const surveys = (state = initialState, action) => {
    switch (action.type) {
        case HANDLE_SURVEY_PROCEDURE_MODAL:
            return {
                ...state,
                surveyProcedureModal: action.payload,
            };

        case HANDLE_SURVEY_ANNOUNCEMENT_MODAL:
            return {
                ...state,
                surveyAnnouncementModal: action.payload,
            };

        case HANDLE_SURVEY_RECORD_MODAL:
            return {
                ...state,
                surveyRecordModal: action.payload
            }
        default:
            return state;
    }
}

export default surveys;