import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'
import Settings from './Settings';
import api from './api';
import surveys from "./surveys";
import header from "./header";

export default (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  api,
  surveys,
  header,
});
