import React, { useEffect, useState } from "react";
import {
  Slide,
  Button,
  Dialog,
  Snackbar,
  FormLabel,
  IconButton,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
  Divider,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { REQUIRED } from "constants/Validations";
import { useForm } from "react-hook-form";
import { FormFeedback } from "reactstrap";
import { filesExtensionsImgProfile } from "constants/FilesExtensions";
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "constants/Messages";
import api from "services/api";
import storage from "services/storage";
import { formatBytes } from "util/formatFileSize"
import { renderAllowedFiles } from "util/renderAllowedFiles";

const UserImageModal = (props) => {
  const { register, errors, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [message, setMessage] = useState("");
  const [fileInfo, setFileInfoState] = useState({ size: '', type: '' });

  const submit = async (values) => {
    const value = values.photography_custom;

    const filesArray = Array.prototype.slice.call(value);

    if (
      !filesExtensionsImgProfile.includes(
        filesArray[0]?.type || filesArray[0]?.name.split(".").pop()
      )
    ) {
      setMessage("Imagen debe ser un archivo con formato: jpeg, jpg, png.");
      setOpenSnack(true);
      return;
    }

    setLoading(true);

    const { user } = storage.getData();

    const form = new FormData();
    form.append("_method", "PUT");
    form.set("abilities", user.abilities || "");
    form.set("nickname", user.nickname || "");
    form.set("cell_phone", user.cell_phone || "");
    form.set("photography_custom", value[0]);

    const response = await api.user.editProfile(form);
    if (response.success) {
      //Actualizar usuario en localstorage
      storage.setUser(response.user);

      document.location.reload();
    } else {
      setMessage(response.message || DEFAULT_REQUEST_ERROR_MESSAGE);
      setOpenSnack(true);
    }
    setLoading(false);
  };

  function setFileInfo() {
    var element = document.getElementById('photography_custom');
    setFileInfoState({ size: formatBytes(element.files.item(0).size), type: element.files.item(0).type })
  };

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Slide}
      onClose={props.handleDialogClose}
    >
      <form onSubmit={handleSubmit(submit)}>
        <DialogTitle>Seleccione una imagen</DialogTitle>
        <DialogContent>
          <FormLabel htmlFor="photography_custom">Fotografía</FormLabel>
          <input
            id="photography_custom"
            name="photography_custom"
            type="file" //1
            className="form-control border-0 mb-2"
            onChange={setFileInfo}
            accept={filesExtensionsImgProfile}
            ref={register({
              required: REQUIRED,
            })}
            margin="none"
            size="small"
          />
          <div className="d-flex">
            <span>{fileInfo.size}</span>
            <Divider orientation="vertical" variant="middle" />
            <span>{fileInfo.type}</span>
          </div>
          {errors.photography_custom && (
            <FormFeedback style={{ display: "block" }} type="invalid">
              {errors.photography_custom.message}
            </FormFeedback>
          )}
          <div className="col-12">
            <span className="text-red"> 1 Archivo permitido de 10MB máximo<br /> {renderAllowedFiles({ image: true })} </span>
          </div>
        </DialogContent>
        <DialogActions className="pb-4 pr-4 pt-0">
          {loading ? (
            <div className="loader-view text-center w-100">
              <CircularProgress />
            </div>
          ) : (
            <>
              <Button
                variant="outlined"
                onClick={props.handleDialogClose}
                className="mi_muro-btn_gray"
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                type="submit"
                className="mi_muro-btn_magenta"
              >
                Guardar
              </Button>
            </>
          )}
        </DialogActions>
      </form>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={15000}
        open={openSnack}
        onClose={() => setOpenSnack(false)}
        ContentProps={{
          "aria-describedby": "message-id",
          className: "bg-danger",
        }}
        message={<span id="message-id">{message}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => setOpenSnack(false)}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Dialog>
  );
};

export default UserImageModal;
