import {
  CLOSE_SNACKBAR,
  USER_REQUEST,
  WORK_GROUP_REQUEST,
  PROCEDURE_REQUEST,
  RECORD_REQUEST,
  NOTIFICATION_REQUEST,
  SURVEY_REQUEST,
  TUTORIAL_REQUEST,
} from "constants/ActionTypes";

const initialState = {
  user: {
    message: "",
  },
  workGroup: {
    message: "",
  },
  procedure: {
    message: "",
  },
  notification: {
    message: "",
  },
  record: {
    message: "",
  },
  survey: {
    message: "",
  },
  tutorial: {
    message: "",
  },
};

const api = (state = initialState, action) => {
  switch (action.type) {
    case USER_REQUEST:
      return {
        ...state,
        user: {
          message: action.payload.message,
          success: action.payload.success || state.user.success,
        },
      };
    case WORK_GROUP_REQUEST:
      return {
        ...state,
        workGroup: {
          message: action.payload.message,
          success: action.payload.success || state.workGroup.success,
        },
      };
    case PROCEDURE_REQUEST:
      return {
        ...state,
        procedure: {
          message: action.payload.message,
          success: action.payload.success || state.procedure.success,
        },
      };
    case NOTIFICATION_REQUEST:
      return {
        ...state,
        notification: {
          message: action.payload.message,
          success: action.payload.success || state.notification.success,
        },
      };
    case RECORD_REQUEST:
      return {
        ...state,
        record: {
          message: action.payload.message,
          success: action.payload.success || state.procedure.success,
        },
      };
    case SURVEY_REQUEST:
      return {
        ...state,
        survey: {
          message: action.payload.message,
          success: action.payload.success || state.survey.success,
        },
      };
    case TUTORIAL_REQUEST:
      return {
        ...state,
        tutorial: {
          message: action.payload.message,
          success: action.payload.success || state.tutorial.success,
        }
      }
    case CLOSE_SNACKBAR:
      return {
        user: {
          message: "",
        },
        workGroup: {
          message: "",
        },
        procedure: {
          message: "",
        },
        notification: {
          message: "",
        },
        record: {
          message: "",
        },
        survey: {
          message: "",
        },
        tutorial: {
          message: "",
        },
      };
    default:
      return state;
  }
};

export default api;
