import {
    HANDLE_SURVEY_PROCEDURE_MODAL,
    HANDLE_SURVEY_ANNOUNCEMENT_MODAL,
    HANDLE_SURVEY_RECORD_MODAL
} from "../constants/ActionTypes";

export function handleSurveyProcedureModal(payload) {
    return { type: HANDLE_SURVEY_PROCEDURE_MODAL, payload }
}

export function handleSurveyAnnouncementModal(payload) {
    return { type: HANDLE_SURVEY_ANNOUNCEMENT_MODAL, payload }
}

export function handleSurveyRecordModal(payload) {
    return { type: HANDLE_SURVEY_RECORD_MODAL, payload }
}
