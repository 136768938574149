import { sendRequest } from "util/utils";
import storage from "services/storage";

const staticUsers = {
  "ROCA003O2034WTRH03": {
    clave_presupuestal: [{ clave: "01ABC2123D", status: "Ocupada", nominal_work_center: "14ADG100R", "laboral_work_center": "BENITO JUAREZ" }],
    nom_emp: "Despacho del Secretario",
    rfc: "ROCA0034TY12",
    num_afil_imss: "345411638",
    num_afil_issste: "80800927",
    edo_nac: "14",
    estado_nac: "Jalisco",
    sexo: "M",
    curp: "ROCA003O2034WTRH03",
    niv_academico: "Doctorado",
    nivel_academico: "Doctorado",
    fec_nac: "04 de enero de 1980",
    edo_civil: "2",
    estado_civil: "Casado",
    emp_dom: "Uruguay, 3392",
    emp_dom_col: "Jardines de la Cruz",
    emp_dom_pob: "Guadalajara",
    emp_dom_cp: "00039",
    correo: "pruebas3@jaliscoedu.mx",
    bco_admdor: "341",
    emp_cta_bancaria: "0039102039",
    telefono: "3216541472",
    ant_serv_est: "158",
    ant_serv_fed: "200",
    fec_ing_est: "Noviembre de 2017",
    fec_ing_fed: "Enero de 2015",
    ant_func_est: "100",
    ant_func_fed: "110"

  },
  "LOMA44312034HTCV04": {
    clave_presupuestal: [{ clave: "01ABC4324D", status: "Ocupada", nominal_work_center: "14BDG100E", "laboral_work_center": "NIÑOS HEROES" }],
    nom_emp: "Adrián López Morales",
    rfc: "LOMA6021FG17",
    num_afil_imss: "275456691",
    num_afil_issste: "54800237",
    edo_nac: "14",
    estado_nac: "Jalisco",
    sexo: "M",
    curp: "LOMA44312034HTCV04",
    niv_academico: "Licenciatura",
    nivel_academico: "Licenciatura",
    fec_nac: "15 de febrero de 1990",
    edo_civil: "2",
    estado_civil: "Soltero",
    emp_dom: "San Felipe, 4492",
    emp_dom_col: "18 de Marzo",
    emp_dom_pob: "Guadalajara",
    emp_dom_cp: "44960",
    correo: "pruebas2@jaliscoedu.mx",
    bco_admdor: "525",
    emp_cta_bancaria: "1232002081",
    telefono: "",
    ant_serv_est: "188",
    ant_serv_fed: "",
    fec_ing_est: "Mayo de 2015",
    fec_ing_fed: "",
    ant_func_est: "100",
    ant_func_fed: ""
  },
  "TOAF57832034WQGV34": {
    clave_presupuestal: [{ clave: "01ABC1234D", status: "Ocupada", nominal_work_center: "14CDG100D", "laboral_work_center": "CUAUHTEMOC" }, { clave: "03ABC2123D", status: "Ocupada", nominal_work_center: "14CDG100D", "laboral_work_center": "ALVARO OBREGON" }],
    nom_emp: "Fernando Torres Aguilar",
    rfc: "TOAF3921TR45",
    num_afil_imss: "562419499",
    num_afil_issste: "86574927",
    estado_nac: "Jalisco",
    edo_nac: "14",
    sexo: "M",
    curp: "TOAF57832034WQGV34",
    niv_academico: "Maestria",
    nivel_academico: "Maestria",
    fec_nac: "27 de julio de 1985",
    edo_civil: "2",
    estado_civil: "Casado",
    emp_dom: "Palma sola, 320",
    emp_dom_col: "Lopez de legaspi",
    emp_dom_pob: "Guadalajara",
    emp_dom_cp: "44960",
    correo: "pruebas1@jaliscoedu.mx",
    bco_admdor: "540",
    emp_cta_bancaria: "1402002029",
    telefono: "3314759687",
    ant_serv_est: "150",
    ant_serv_fed: "212",
    fec_ing_est: "Septiembre de 2013",
    fec_ing_fed: "Junio de 2010",
    ant_func_est: "50",
    ant_func_fed: "100"
  },
  "COVL4940034DRFV97": {
    clave_presupuestal: [{ clave: "01ABC1122D", status: "Ocupada", nominal_work_center: "14CDG100F", "laboral_work_center": "JUAN RULFO" }],
    nom_emp: "Liliana Dorantes Coronado",
    rfc: "COVL8410RR98",
    num_afil_imss: "961019232",
    num_afil_issste: "43300927",
    estado_nac: "Jalisco",
    edo_nac: "14",
    sexo: "F",
    curp: "COVL4940034DRFV97",
    niv_academico: "Maestria",
    nivel_academico: "Maestria",
    fec_nac: "15 de mayo de 1986",
    edo_civil: "2",
    estado_civil: "Casada",
    emp_dom: "Constitución, 459",
    emp_dom_col: "Tonalá, Centro",
    emp_dom_pob: "Tonalá",
    emp_dom_cp: "45400",
    correo: "pruebastramites@jaliscoedu.mx",
    bco_admdor: "940",
    emp_cta_bancaria: "2429304323",
    telefono: "6549876542",
    ant_serv_est: "",
    ant_serv_fed: "286",
    fec_ing_est: "",
    fec_ing_fed: "Agosto de 2010",
    ant_func_est: "",
    ant_func_fed: "150"
  },
  "VIAA820609MJCIRL011": {
    clave_presupuestal: [{ clave: "01ABC3833G", status: "Ocupada", nominal_work_center: "15CZH970K", "laboral_work_center": "SALVADOR MEGIAS" }],
    nom_emp: "Alma Vilar Ares",
    rfc: "VIAA820609V45",
    num_afil_imss: "684565896",
    num_afil_issste: "78564321",
    estado_nac: "Jalisco",
    edo_nac: "14",
    sexo: "F",
    curp: "VIAA820609MJCIRL011",
    niv_academico: "Maestria",
    nivel_academico: "Maestria",
    fec_nac: "9 de junio de 1982",
    edo_civil: "2",
    estado_civil: "Casada",
    emp_dom: "Constitución, 459",
    emp_dom_col: "Tonalá, Centro",
    emp_dom_pob: "Tonalá",
    emp_dom_cp: "45400",
    correo: "pruebas.mimuro1@jaliscoedu.mx",
    bco_admdor: "940",
    emp_cta_bancaria: "2345846546",
    telefono: "7865485654",
    ant_serv_est: "",
    ant_serv_fed: "286",
    fec_ing_est: "",
    fec_ing_fed: "Agosto de 2010",
    ant_func_est: "",
    ant_func_fed: "150"
  },
  "RARJ890121HJCMIS09": {
    clave_presupuestal: [{ clave: "01ABC7736H", status: "Ocupada", nominal_work_center: "17KJG100P", "laboral_work_center": "SANTOS FIGUEROA" }],
    nom_emp: "Jose Ramon Rios",
    rfc: "RARJ890121K55",
    num_afil_imss: "654354452",
    num_afil_issste: "79846556",
    edo_nac: "14",
    estado_nac: "Jalisco",
    sexo: "M",
    curp: "RARJ890121HJCMIS09",
    niv_academico: "Licenciatura",
    nivel_academico: "Licenciatura",
    fec_nac: "21 de enero de 1989",
    edo_civil: "2",
    estado_civil: "Soltero",
    emp_dom: "San Felipe, 4492",
    emp_dom_col: "18 de Marzo",
    emp_dom_pob: "Guadalajara",
    emp_dom_cp: "44960",
    correo: "pruebas.mimuro2@jaliscoedu.mx",
    bco_admdor: "478",
    emp_cta_bancaria: "1798456665",
    telefono: "9665822321",
    ant_serv_est: "188",
    ant_serv_fed: "",
    fec_ing_est: "Mayo de 2015",
    fec_ing_fed: "",
    ant_func_est: "100",
    ant_func_fed: ""
  },
  "DELL911012HJCLII07": {
    clave_presupuestal: [{ clave: "01ABC8557J", status: "Ocupada", nominal_work_center: "14IJK100L", "laboral_work_center": "FEDERICO TRUJILLO" }],
    nom_emp: "Luis Delgado Lima",
    rfc: "DELL911012BG5",
    num_afil_imss: "988652315",
    num_afil_issste: "14885896",
    edo_nac: "14",
    estado_nac: "Jalisco",
    sexo: "M",
    curp: "DELL911012HJCLII07",
    niv_academico: "Licenciatura",
    nivel_academico: "Licenciatura",
    fec_nac: "12 de octubre de 1991",
    edo_civil: "2",
    estado_civil: "Soltero",
    emp_dom: "San Felipe, 4492",
    emp_dom_col: "18 de Marzo",
    emp_dom_pob: "Guadalajara",
    emp_dom_cp: "44960",
    correo: "pruebas.mimuro3@jaliscoedu.mx",
    bco_admdor: "966",
    emp_cta_bancaria: "7569112585",
    telefono: "",
    ant_serv_est: "188",
    ant_serv_fed: "",
    fec_ing_est: "Mayo de 2015",
    fec_ing_fed: "",
    ant_func_est: "100",
    ant_func_fed: ""
  },
  "REMM850726MJCQTI06": {
    clave_presupuestal: [{ clave: "01ABC6585H", status: "Ocupada", nominal_work_center: "17DFH337H", "laboral_work_center": "VIDAL MALDONADO" }],
    nom_emp: "Minerva Requena Mateos",
    rfc: "REMM8507263LA",
    num_afil_imss: "684565896",
    num_afil_issste: "78564321",
    estado_nac: "Jalisco",
    edo_nac: "14",
    sexo: "F",
    curp: "REMM850726MJCQTI06",
    niv_academico: "Maestria",
    nivel_academico: "Maestria",
    fec_nac: "26 de julio de 1985",
    edo_civil: "2",
    estado_civil: "Casada",
    emp_dom: "Constitución, 459",
    emp_dom_col: "Tonalá, Centro",
    emp_dom_pob: "Tonalá",
    emp_dom_cp: "45400",
    correo: "pruebas.mimuro4@jaliscoedu.mx",
    bco_admdor: "742",
    emp_cta_bancaria: "8854684332",
    telefono: "7658554656",
    ant_serv_est: "",
    ant_serv_fed: "286",
    fec_ing_est: "",
    fec_ing_fed: "Agosto de 2010",
    ant_func_est: "",
    ant_func_fed: "150"
  },
  "GONC900515MJCLRL00": {
    clave_presupuestal: [{ clave: "01ABC7445H", status: "Ocupada", nominal_work_center: "12ILO100F", "laboral_work_center": "NIL GABARRI" }],
    nom_emp: "Claudia Gloria Naranjo",
    rfc: "GONC9005154ZA",
    num_afil_imss: "988652315",
    num_afil_issste: "14885896",
    edo_nac: "14",
    estado_nac: "Jalisco",
    sexo: "M",
    curp: "GONC900515MJCLRL00",
    niv_academico: "Licenciatura",
    nivel_academico: "Licenciatura",
    fec_nac: "15 de Mayo de 1990",
    edo_civil: "2",
    estado_civil: "Casada",
    emp_dom: "San Felipe, 4492",
    emp_dom_col: "18 de Marzo",
    emp_dom_pob: "Guadalajara",
    emp_dom_cp: "44960",
    correo: "pruebas.mimuro5@jaliscoedu.mx",
    bco_admdor: "740",
    emp_cta_bancaria: "9642281124",
    telefono: "7654485586",
    ant_serv_est: "188",
    ant_serv_fed: "",
    fec_ing_est: "Mayo de 2015",
    fec_ing_fed: "",
    ant_func_est: "100",
    ant_func_fed: ""
  },
};

const login = async data => {
  const route = "/login/google";
  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    body: JSON.stringify(data)
  };
  return await sendRequest(route, request);
};

const getUserList = async (historical = false, page = 1, term = "", user_viewer = false) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/users?historical=${historical}&page=${page}&term=${term}&user_viewer=${user_viewer}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const createUser = async user => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/users`,
    request = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
      body: user
    };
  return await sendRequest(route, request);
};

const editUser = async (userId, user) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/users/${userId}`,
    request = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
      body: user
    };
  return await sendRequest(route, request);
};

const editProfile = async user => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/users`,
    request = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
      body: user
    };
  return await sendRequest(route, request);
};

const getUser = async userId => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/users/${userId}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const getCurrentUser = async userId => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/users/authenticated_user`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const getUserExternal = async curp => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/users/external?curp=${curp}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const changeUserStatus = async (userId, status) => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/users/${userId}/status`,
    request = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
      body: JSON.stringify({ status })
    };
  return await sendRequest(route, request);
};

const searchUsers = async id => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/users/rol-follow-up-link-to-procedure?workGroupId=${id}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const getWorkAreasList = async () => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/work_areas`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const getWorkGroupsList = async () => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/work_groups/selector`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const getOrganizationsList = async () => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/organizations`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const getRolesList = async () => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/roles`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const getStatusUpdateRequest = async id => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/records/update_requests/${id}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
}

const updateGoogleToken = async newToken => {
  const token = `Bearer ${storage.getToken()}`;
  const route = "/google/token",
    request = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
      body: JSON.stringify(newToken)
    };
  return await sendRequest(route, request, false, false);
};

const updateEmergencyInfo = async (data) => {
  const token = `Bearer ${storage.getToken()}`;
  const route = "/users/emergency-info",
    request = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
      body: JSON.stringify(data)
    };
  return await sendRequest(route, request);
}

const downloadProofService = async () => {
  const token = `Bearer ${storage.getToken()}`,
      route = `/users/proof-service`,
      request = {
          method: "GET",
          headers: {
              Accept: "application/json",
              Authorization: token,
              token: storage.getSSID(),
          },
      };
  return await sendRequest(route, request, true);
};

const safeGuards = async (page = 1) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/users/safeguards?page=${page}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const verifyCurp = async curp => {
  const token = `Bearer ${storage.getToken()}`;
  const route = '/users/curp-verification',
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const getProfiles = async curp => {
  const token = `Bearer ${storage.getToken()}`;
  const route = '/profiles',
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const getAvailableIncomes = async () => {
  const token = `Bearer ${storage.getToken()}`,
      route = `/users/getYearsDeductions`,
      request = {
          method: "GET",
          headers: {
              Accept: "application/json",
              Authorization: token,
              token: storage.getSSID(),
          },
      };
  return await sendRequest(route, request); 
}

const downloadProofIncome = async (year) => {
  const token = `Bearer ${storage.getToken()}`,
      route = `/users/getPerceptionsAndDeductionsPDF?year=${year}`,
      request = {
          method: "GET",
          headers: {
              Accept: "application/json",
              Authorization: token,
              token: storage.getSSID(),
          },
      };
  return await sendRequest(route, request, true);
};

export default {
  login,
  getUserList,
  createUser,
  editUser,
  editProfile,
  getUser,
  changeUserStatus,
  searchUsers,
  getWorkAreasList,
  getWorkGroupsList,
  getOrganizationsList,
  getRolesList,
  getCurrentUser,
  getUserExternal,
  getStatusUpdateRequest,
  updateGoogleToken,
  updateEmergencyInfo,
  downloadProofService,
  safeGuards,
  verifyCurp,
  getProfiles,
  getAvailableIncomes,
  downloadProofIncome,
};
