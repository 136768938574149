import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Header from "components/Header/index";
import Sidebar from "containers/SideNav/index";
import Tour from "../components/Tour/index";
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION
} from "constants/ActionTypes";
import { isIOS, isMobile } from "react-device-detect";
import asyncComponent from "../util/asyncComponent";
import TopNav from "components/TopNav";

class App extends React.Component {
  render() {
    const {
      match,
      drawerType,
      navigationStyle,
      horizontalNavPosition
    } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? "fixed-drawer"
      : drawerType.includes(COLLAPSED_DRAWER)
        ? "collapsible-drawer"
        : "mini-drawer";

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add("ios-mobile-view-height");
    } else if (document.body.classList.contains("ios-mobile-view-height")) {
      document.body.classList.remove("ios-mobile-view-height");
    }

    return (
      <div className={`app-container ${drawerStyle}`}>
        <Tour />

        <Sidebar />
        <div className="app-main-container">
          <div
            className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION
              ? "app-header-horizontal"
              : ""
              }`}
          >
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === ABOVE_THE_HEADER && (
                <TopNav styleName="app-top-header" />
              )}
            <Header {...this.props} />
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
          </div>

          <main className="app-main-content-wrapper">
            <div className="app-main-content mi_muro-bg_gray-light">
              <Switch>
                <Route
                  path={`${match.url}/blog`}
                  component={asyncComponent(() => import("./routes/Blog"))}
                />
                <Route
                  path={`${match.url}/blog-post/:id`}
                  component={asyncComponent(() =>
                    import("./routes/UniqueBlogPost")
                  )}
                />
                <Route
                  path={`${match.url}/blog-post/:id`}
                  component={asyncComponent(() =>
                    import("./routes/UniqueBlogPost")
                  )}
                />
                <Route
                  path={`${match.url}/profile`}
                  component={asyncComponent(() => import("./routes/Profile"))}
                />
                <Route
                  path={`${match.url}/users`}
                  component={asyncComponent(() => import("./routes/Users"))}
                />

                <Route
                  path={`${match.url}/procedures/create`}
                  component={asyncComponent(() =>
                    import("app/routes/Procedures/components/CreateProcedure")
                  )}
                />
                <Route
                  path={`${match.url}/procedures/edit/:id`}
                  component={asyncComponent(() =>
                    import("app/routes/Procedures/components/EditProcedure")
                  )}
                />
                <Route
                  path={`${match.url}/procedures/create-appointment/:id`}
                  component={asyncComponent(() =>
                    import("app/routes/Procedures/components/CreateAppointment")
                  )}
                />
                <Route
                  path={`${match.url}/procedures`}
                  component={asyncComponent(() =>
                    import("./routes/Procedures")
                  )}
                />
                <Route
                  path={`${match.url}/notifications`}
                  component={asyncComponent(() => import("./routes/Notifications"))}
                />
                <Route
                  path={`${match.url}/surveys`}
                  component={asyncComponent(() => import("./routes/Surveys"))}
                />
                <Route
                  path={`${match.url}/administration`}
                  component={asyncComponent(() => import("./routes/Admin"))}
                />
                <Route
                  path={`${match.url}/day-absence`}
                  component={asyncComponent(() => import("./routes/DayAbsenceForm"))}
                />
                <Route
                  path={`${match.url}/help-content`}
                  component={asyncComponent(() => import("./routes/Help"))}
                />
                <Route
                  path={`${match.url}/media`}
                  component={asyncComponent(() => import("./routes/Media"))}
                />

                <Route
                  component={asyncComponent(() =>
                    import("components/Error404")
                  )}
                />
              </Switch>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  return { drawerType, navigationStyle, horizontalNavPosition };
};
export default withRouter(connect(mapStateToProps)(App));
