import { sendRequest } from "util/utils";
import storage from "services/storage";


const getAvailableSchedules = async (params) => {
    let paramsString = ""
    for (let value in params) {
        paramsString = paramsString.concat(`${value}=${params[value]}&`)
    }
    paramsString = paramsString.slice(0, -1)
    const token = `Bearer ${storage.getToken()}`;
    const route = `/schedules/appoinments?${paramsString}`;
    const request = {
        method: "GET",
        headers: {
            "Accept": "application/json",
            Authorization: token,
            token: storage.getSSID(),
        },
    };
    return await sendRequest(route, request);
};

const getPlaces = async (procedureId) => {
    const token = `Bearer ${storage.getToken()}`;
    const route = `/schedules/places${procedureId ? `/${procedureId}` : ""}`;
    const request = {
        method: "GET",
        headers: {
            "Accept": "application/json",
            Authorization: token,
            token: storage.getSSID(),
        },
    };
    // return { success: true, places: [{ id: 1, place: "Av lázaro cárdenas 3" }, { id: 2, place: "Av tizoc 97" }] }
    return await sendRequest(route, request);
};

const getSchedulesByResponsible = async () => {
    const token = `Bearer ${storage.getToken()}`;
    const route = `/schedules/by_responsible`;
    const request = {
        method: "GET",
        headers: {
            "Accept": "application/json",
            Authorization: token,
            token: storage.getSSID(),
        },
    };
    return await sendRequest(route, request);
};

const getTakenSchedules = async (type, procedure_id, place_id, requester_id) => {
    const token = `Bearer ${storage.getToken()}`;
    let route = `/schedules/taken?type=${type}`;
    if (procedure_id)
        route += `&procedure_id=${procedure_id}`;
    if (place_id)
        route += `&place_id=${place_id}`;
    if (requester_id)
        route += `&requester_id=${requester_id}`;
    const request = {
        method: "GET",
        headers: {
            "Accept": "application/json",
            Authorization: token,
            token: storage.getSSID(),
        },
    };
    return await sendRequest(route, request);
};

const updateProcedureSchedule = async (data) => {
    console.log("🚀 ~ file: appointments.js:74 ~ updateProcedureSchedule ~ data:", data.values())
    const token = `Bearer ${storage.getToken()}`;
    const route = `/schedules`;
    const request = {
        method: "POST",
        headers: {
            Authorization: token,
            token: storage.getSSID(),
        },
        body: data
    };
    return await sendRequest(route, request);
};

const createAppointment = async (data, procedure_id) => {
    const token = `Bearer ${storage.getToken()}`;
    const route = `/appointments/${procedure_id}`;
    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            Authorization: token,
            token: storage.getSSID(),
        },
        body: data
    };
    return await sendRequest(route, request);
};

const createLevel4Appointment = async (data) => {
    const token = `Bearer ${storage.getToken()}`;
    const route = `/appointments/follow_procedure`;
    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            Authorization: token,
            token: storage.getSSID(),
        },
        body: data
    };
    return await sendRequest(route, request);
};

const createAppointmentByResponsible = async (data, procedureRequestId) => {
    const token = `Bearer ${storage.getToken()}`;
    const route = `/appointments/create_by_responsible/${procedureRequestId}`;
    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            Authorization: token,
            token: storage.getSSID(),
        },
        body: data
    };
    return await sendRequest(route, request);
};

const cancelAppointment = async (procedure_request_id) => {
    const form = new FormData()
    form.append("_method", "PUT")
    form.append("status_appointment", "Cancelada")
    const token = `Bearer ${storage.getToken()}`;
    const route = `/procedures/requests/${procedure_request_id}/status-appointment`;
    const request = {
        method: "POST",
        headers: {
            "Accept": "application/json",
            Authorization: token,
            token: storage.getSSID(),
        },
        body: form
    };
    return await sendRequest(route, request);
};

const getQRData = async (qrId) => {
    const token = `Bearer ${storage.getToken()}`;
    const route = `/appointments/read/qr/${qrId}`;
    const request = {
        method: "GET",
        headers: {
            "Accept": "application/json",
            Authorization: token,
            token: storage.getSSID(),
        },
    };
    return await sendRequest(route, request);
};

export default
    {
        createAppointment,
        updateProcedureSchedule,
        getSchedulesByResponsible,
        getTakenSchedules,
        getAvailableSchedules,
        createLevel4Appointment,
        createAppointmentByResponsible,
        cancelAppointment,
        getPlaces,
        getQRData
    }