export const filesExtensions =
  " .ods, .csv, text/csv, .pdf, image/png, image/jpeg,.doc,.docx,.xml, text/xml, application/xml, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, " +
  "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,  application/vnd.ms-powerpoint, " +
  "application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow," +
  "application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf";
export const filesExtensionsAnnouncement =
  " .ods, image/png, image/jpeg, .pdf, .doc,.docx, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,   " +
  " application/vnd.ms-powerpoint, application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow," +
  "application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf";
export const filesExtensionsImgProfile = "image/png, image/jpeg";
export const filesExtensionsElectronicRecord = "image/png, image/jpeg, application/pdf, .pdf";

export const filesExtensionsChat =
  " .ods, .pdf, image/png, image/jpeg,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, " +
  "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,  application/vnd.ms-powerpoint, " +
  "application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow," +
  "application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf, .xml, .gif, .mp3, .mp4, .mov, .zip, .rar";

export const filesExtensionsNotifications =
  ".doc, .docm, .docx, .pdf, .zip, .rar, .xlsx, .xlsm, xlsb, image/png, image/jpeg, image/jpg, video/mp4,application/pdf, application/x-zip-compressed, video/webm, video/ogg"

export const filesExtensionsActivities =
  ".ods, .csv, text/csv, .pdf, .doc, .docx, .xml, text/xml, application/xml, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,  application/vnd.ms-powerpoint, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/vnd.openxmlformats-officedocument.presentationml.presentation";
