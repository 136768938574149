import React, { Component } from "react";
import thumbnail0 from "assets/images/default_tutorial_0.jpg";
import thumbnail1 from "assets/images/default_tutorial_1.jpg";
import thumbnail2 from "assets/images/default_tutorial_2.jpg";
import baseUrl from "constants/Api";
import "./styles.scss";

const thumbnails = [
  thumbnail0,
  thumbnail1,
  thumbnail2,
]

class GridImage extends Component {
  handleCancel() {
    this.props.handleToggle();
  }

  render() {
    const { mediaList, handleClick } = this.props;


    return (
      <div className="row">
        {
          mediaList.length === 1
          ? (
            <div className="col-12">
              <TutorialItem tutorial={mediaList[0]} handleClick={handleClick} id={0} />
            </div>
          )
          : (
            mediaList.map((tutorial, idx) => {
              return (
                <div className="col-md-6 col-12">
                  <TutorialItem tutorial={tutorial} handleClick={handleClick} id={idx} />
                </div>
              );
            })
          )
        }
      </div>
    );
  }
}

function TutorialItem(props) {
  const { tutorial, handleClick, id } = props;

  const rnd = Math.floor(Math.random()*3);
  const defaultThumbnail = thumbnails[rnd];

  return (
    <div className="card border-radius-20">
      <div className="card-img pointer" onClick={() => handleClick(id)}>
        {
          tutorial.file_type === "Imagen"
          ? <img src={`${baseUrl}/${tutorial.file}`} alt="file"  />
          : <>
            <img src={tutorial.thumbnail ? `${baseUrl}/${tutorial.thumbnail}` : defaultThumbnail} alt="file"  />
            <div className="video-play-button">
              <div className="d-flex justify-content-center pointer play-button-container">
                <i className="zmdi zmdi-play text-white zmdi-hc-5x d-flex align-self-center" />
              </div>
            </div>
          </>
        }
      </div>

      <div className="p-3" style={{minHeight: "90px"}}>
        <p className="break-text-two-lines">{tutorial.title || "Sin título"}</p>
      </div>
    </div>
  );
}

export default GridImage;
