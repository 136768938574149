import storage from "services/storage";
import { sendRequest } from "util/utils";

// Notifications lists

const listNotifications = async () => {
  const token = `Bearer ${storage.getToken()}`,
    route = "/notifications",
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

const getMassiveNotificationsList = async (page) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/notifications/list?page=${page}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

const getMyNotificationsList = async (page) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/received?page=${page}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

const getTrakingNotificationsList = async () => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/read`,
    request = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

//Notifications CRUD

const createNotification = async (data) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/notify`,
    request = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
      body: data,
    };
  return await sendRequest(route, request);
};

const deleteNotification = async (id) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/notifications/${id}`,
    request = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

const getReceivedNotification = async (id) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/received/${id}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

const getCreatedNotification = async (id) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/notification/${id}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

//Search methods

const getMassiveBySearch = async (search, page) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/notifications/list?search=${search}&page=${page}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

const getMyNotificationsBySearch = async (search, page) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/received?search=${search}&page=${page}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

const getTrackingNotificationsBySearch = async (search, page) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/read?&page=${page}&search=${search}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

//Utility

const viewedNotification = async (id) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/notify-viewed/${id}`,
    request = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

const getRecipients = async (id, page, search, status = "") => {
  const route =
    status != ""
      ? `/recipients/${id}?page=${page}&search=${search}&filter=${
          status === "visto" ? true : false
        }`
      : `/recipients/${id}?page=${page}&search=${search}`;
  const token = `Bearer ${storage.getToken()}`,
    request = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

const getUnSeenNotifications = async () => {
  const token = `Bearer ${storage.getToken()}`,
    route = "/unseen",
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request, false, false);
};

const acceptNotification = async (id, data) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/approve_notification/${id}`,
    request = {
      method: "PUT",
      headers: {
        'Content-Type': 'application/json',
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
      body: data
    };
  return await sendRequest(route, request);
};

export default {
  listNotifications,
  getMassiveNotificationsList,
  getMyNotificationsList,
  getTrakingNotificationsList,
  createNotification,
  deleteNotification,
  getMassiveBySearch,
  getMyNotificationsBySearch,
  getTrackingNotificationsBySearch,
  viewedNotification,
  getRecipients,
  getUnSeenNotifications,
  getCreatedNotification,
  getReceivedNotification,
  acceptNotification,
};
