import {applyMiddleware, compose, createStore} from 'redux';
import reducers from '../reducers/index';
import {createBrowserHistory} from 'history'
import {routerMiddleware} from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas/index';
import ReactGA from 'react-ga';

ReactGA.initialize(process.env.REACT_APP_ANALYTICS_KEY);

const history = createBrowserHistory({ basename: "/" });

const proceduresPages = [
  'Mi Muro RecreApp - Mis Trámites: Agendar cita',
  'Mi Muro RecreApp - Mis Trámites: Creación de ficha',
  'Mi Muro RecreApp - Mis Trámites: Edición de ficha',
  'Mi Muro RecreApp - Mis Trámites: Respuestas de formulario',
  'Mi Muro RecreApp - Mis Trámites: Trámites a mi cargo',
  'Mi Muro RecreApp - Mis Trámites: Validación de trámites',
  'Mi Muro RecreApp - Mis Trámites: Ver ficha',
  'Mi Muro RecreApp - Mis Trámites: Sala de seguimiento',
  'Mi Muro RecreApp - Mis Trámites: Ver sala de seguimiento'
];

history.listen(location => {
  setTimeout(() => {
    if (document.title.includes('Mi Muro RecreApp - Mis Trámites:') && !proceduresPages.includes(document.title)) return;
    ReactGA.set({ page: location.pathname, title: document.title });
    ReactGA.pageview(location.pathname);
  }, 1000);
});

const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, routeMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


export default function configureStore(initialState) {
  const store = createStore(reducers(history), initialState,
    composeEnhancers(applyMiddleware(...middlewares)));

  sagaMiddleware.run(rootSaga);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers/index', () => {
      const nextRootReducer = require('../reducers/index');
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
}
export {history};
