import { GENERAL_ADMIN_ROLE, GROUP_ADMIN_ROLE, RESPONSIBLE_ROLE, USER_ROLE } from "./Roles";

export const userTypes = [
  {
    id: USER_ROLE,
    name: USER_ROLE,
  },
  {
    id: RESPONSIBLE_ROLE,
    name: RESPONSIBLE_ROLE,
  },
  {
    id: GROUP_ADMIN_ROLE,
    name: GROUP_ADMIN_ROLE,
  },
  {
    id: GENERAL_ADMIN_ROLE,
    name: GENERAL_ADMIN_ROLE,
  },
];

export const emptyOptionSelect = {
  id: "",
  name: "Seleccione una opción"
}

export const systemRoutes = [
  {
    id: "/app/blog-post/:id",
    name: "Red social: Ver publicación",
    section: 1,
    placeInSection: 2,
  },
  {
    id: "/app/blog",
    name: "Red social",
    section: 1,
    placeInSection: 1,
  },
  {
    id: "/app/profile/tracking/:id",
    name: "Mi expediente: Ver sala de seguimiento",
    section: 2,
    placeInSection: 15,
  },
  {
    id: "/app/profile/tracking",
    name: "Mi expediente: Sala de seguimiento a expedientes",
    section: 2,
    placeInSection: 14,
  },
  {
    id: "/app/profile/records-validation/historical",
    name: "Mi expediente: Historial de solicitudes",
    section: 2,
    placeInSection: 12,
  },
  {
    id: "/app/profile/records-validation/request/:id",
    name: "Mi expediente: Ver solicitud por responsable",
    section: 2,
    placeInSection: 11,
  },
  {
    id: "/app/profile/records-validation",
    name: "Mi expediente: Solicitudes a mi cargo",
    section: 2,
    placeInSection: 10,
  },
  {
    id: "/app/profile/records",
    name: "Mi expediente: Visualización de expedientes",
    section: 2,
    placeInSection: 13,
  },
  {
    id: "/app/profile/my-records/edit/:id",
    name: "Mi expediente: Edición de solicitud",
    section: 2,
    placeInSection: 9,
  },
  {
    id: "/app/profile/my-records/details/:id",
    name: "Mi expediente: Ver mi solicitud",
    section: 2,
    placeInSection: 8,
  },
  {
    id: "/app/profile/my-records",
    name: "Mi expediente: Mis solicitudes",
    section: 2,
    placeInSection: 7,
  },
  // {
  //   id: "/app/profile/electronic-record/:id",
  //   name: "Mi expediente: Mis documentos",
  // },
  { //Mismo componente que el anterior
    id: "/app/profile/electronic-record",
    name: "Mi expediente: Mis documentos",
    section: 2,
    placeInSection: 6,
  },
  // {
  //   id: "/app/profile/profile-form/edit/:id",
  //   name: "Mi expediente: Edición de solicitud",
  // },
  {
    id: "/app/profile/profile-form",
    name: "Mi expediente: Solicitud de actualización o aclaración de datos",
    section: 2,
    placeInSection: 2,
  },
  {
    id: "/app/profile/1",
    name: "Mis procedimientos",
    section: 2,
    placeInSection: 3,
  },
  {
    id: "/app/profile/2",
    name: "Mis resguardos",
    section: 2,
    placeInSection: 4,
  },
  {
    id: "/app/profile/3",
    name: "Insignias digitales",
    section: 2,
    placeInSection: 5,
  },
  {
    id: "/app/profile",
    name: "Mi expediente",
    section: 2,
    placeInSection: 1,
  },
  {
    id: "/app/users/:id/edit",
    name: "Usuarios: Edición de usuario",
    section: 7,
    placeInSection: 3,
  },
  {
    id: "/app/users/admin-for-records/:id",
    name: "Usuarios: Edición de visualizador de expediente",
    section: 7,
    placeInSection: 4,
  },
  {
    id: "/app/users/admin-for-records",
    name: "Usuarios: Visualizador de mi expediente",
    section: 7,
    placeInSection: 2,
  },
  {
    id: "/app/users",
    name: "Usuarios",
    section: 7,
    placeInSection: 1,
  },
  {
    id: "/app/procedures/create",
    name: "Mis trámites: Creación de ficha",
    section: 3,
    placeInSection: 2,
  },
  {
    id: "/app/procedures/edit/:id",
    name: "Mis trámites: Edición de ficha",
    section: 3,
    placeInSection: 4,
  },
  {
    id: "/app/procedures/create-appointment/:id",
    name: "Mis trámites: Agendar cita",
    section: 3,
    placeInSection: 7,
  },
  {
    id: "/app/procedures/work-groups/:id",
    name: "Mis trámites: Ver sala de seguimiento",
    section: 3,
    placeInSection: 16,
  },
  {
    id: "/app/procedures/work-groups/",
    name: "Mis trámites: Sala de seguimiento",
    section: 3,
    placeInSection: 15,
  },
  {
    id: "/app/procedures/procedures-in-my-charge/details/:id",
    name: "Mis trámites: Solicitudes a mi cargo - Ver",
    section: 3,
    placeInSection: 13,
  },
  {
    id: "/app/procedures/procedures-in-my-charge/admin-request",
    name: "Mis trámites: Gestión de trámites",
    section: 3,
    placeInSection: 10,
  },
  {
    id: "/app/procedures/procedures-in-my-charge/validate-request",
    name: "Mis trámites: Validación de trámites",
    section: 3,
    placeInSection: 11,
  },
  {
    id: "/app/procedures/procedures-in-my-charge/validate-approve",
    name: "Mis trámites: Validación de solicitudes",
    section: 3,
    placeInSection: 12,
  },
  // {
  //   id: "/app/procedures/procedures-in-my-charge/requests",
  //   name: "Mis trámites: Solicitudes a mi cargo",
  // },
  { //Es lo mismo que el anterior
    id: "/app/procedures/procedures-in-my-charge",
    name: "Mis trámites: Solicitudes a mi cargo",
    section: 3,
    placeInSection: 9,
  },
  {
    id: "/app/procedures/my-procedures/:procedureRequestId/form/:mode/status/:status",
    name: "Mis trámites: Respuestas de formulario",
    section: 3,
    placeInSection: 14,
  },
  {
    id: "/app/procedures/my-procedures",
    name: "Mis trámites: Mis solicitudes",
    section: 3,
    placeInSection: 8,
  },
  {
    id: "/app/procedures/forms/edit/:id",
    name: "Mis trámites: Plantilla para trámites: Edición",
    section: 3,
    placeInSection: 17,
  },
  {
    id: "/app/procedures/forms/generic-questions/edit/:id",
    name: "Formularios dinámicos: Edición pregunta genérica",
    section: 3,
    placeInSection: 21,
  },
  {
    id: "/app/procedures/forms/generic-questions",
    name: "Formularios dinámicos: Preguntas genéricas",
    section: 3,
    placeInSection: 20,
  },
  {
    id: "/app/procedures/forms/form-maker",
    name: "Mis trámites: Plantilla para trámites: Creación",
    section: 3,
    placeInSection: 18,
  },
  {
    id: "/app/procedures/forms",
    name: "Formularios dinámicos",
    section: 3,
    placeInSection: 19,
  },
  {
    id: "/app/procedures/:procedureId/form",
    name: "Mis trámites: Responder formulario",
    section: 3,
    placeInSection: 6,
  },
  {
    id: "/app/procedures/:procedureIdToViewForm/view-form",
    name: "Mis trámites: Ver formulario",
    section: 3,
    placeInSection: 5,
  },
  {
    id: "/app/procedures/:id",
    name: "Mis trámites: Ver ficha",
    section: 3,
    placeInSection: 3,
  },
  {
    id: "/app/procedures",
    name: "Mis trámites",
    section: 3,
    placeInSection: 1,
  },
  {
    id: "/app/notifications/create",
    name: "Notificaciones: Creación",
    section: 5,
    placeInSection: 2,
  },
  {
    id: "/app/notifications/view/:id/:mode",
    name: "Notificaciones: Ver notificación",
    section: 5,
    placeInSection: 4,
  },
  {
    id: "/app/notifications/recipients/:id",
    name: "Notificaciones: Destinatarios",
    section: 5,
    placeInSection: 3,
  },
  {
    id: "/app/notifications",
    name: "Notificaciones",
    section: 5,
    placeInSection: 1,
  },
  {
    id: "/app/surveys/announcements/:id",
    name: "Satisfacción - Convocatorias - Detalle",
    section: 6,
    placeInSection: 10,
  },
  {
    id: "/app/surveys/announcements",
    name: "Satisfacción - Convocatorias",
    section: 6,
    placeInSection: 9,
  },
  {
    id: "/app/surveys/records",
    name: "Satisfacción - Mi expediente",
    section: 6,
    placeInSection: 8,
  },
  {
    id: "/app/surveys/procedures/overall-results/:id",
    name: "Satisfacción - Encuesta global del trámite: TRAMITE",
    section: 6,
    placeInSection: 6,
  },
  {
    id: "/app/surveys/procedures/individual-results/:id",
    name: "Satisfacción - Encuesta individual del trámite: TRAMITE",
    section: 6,
    placeInSection: 7,
  },
  {
    id: "/app/surveys/procedures",
    name: "Satisfacción - Mis trámites",
    section: 6,
    placeInSection: 5,
  },
  {
    id: "/app/surveys/social-media/create",
    name: "Encuestas rápidas red social",
    section: 6,
    placeInSection: 3,
  },
  {
    id: "/app/surveys/social-media/:id",
    name: "Encuestas rápidas red social - Detalle",
    section: 6,
    placeInSection: 4,
  },
  {
    id: "/app/surveys/social-media",
    name: "Encuestas rápidas red social",
    section: 6,
    placeInSection: 2,
  },
  {
    id: "/app/surveys",
    name: "Encuestas",
    section: 6,
    placeInSection: 1,
  },
  {
    id: "/app/help-content/create",
    name: "Crear Tutorial",
    section: 4,
    placeInSection: 2,
  },
  {
    id: "/app/help-content/:id",
    name: "Editar Tutorial",
    section: 4,
    placeInSection: 3,
  },
  {
    id: "/app/help-content",
    name: "Tutoriales",
    section: 4,
    placeInSection: 1,
  },
  {
    id: "/app/day-absence",
    name: "Días inhábiles",
    section: 8,
    placeInSection: 1,
  },
  /*Pendiente agregar páginas de sección Administrar */
];