import user from "./user";
import blog from "./blog";
import notifications from "./notifications";
import workGroups from "./work-groups";
import procedures from "./procedures";
import appointments from "./appointments";
import records from "./records";
import forms from "./forms";
import announcements from "./announcements";
import connections from "./connections";
import surveys from "./surveys";
import surveysProcedures from "./surveysProcedures";
import surveysAnnouncements from "./surveysAnnouncements";
import sgu from "./sgu";
import dayAbsence from "./dayAbsence";
import admin from "./admin";

export default {
  user,
  blog,
  notifications,
  workGroups,
  procedures,
  appointments,
  records,
  forms,
  announcements,
  connections,
  surveys,
  surveysProcedures,
  surveysAnnouncements,
  sgu,
  dayAbsence,
  admin,
};
