import React, { useEffect, useState } from "react";
import TextInput from './TextInput';
import ReactionInput from './ReactionInput';
import RadioRowInput from './RadioRowInput';
import RadioColumnInput from './RadioColumnInput';
import CheckboxInput from './CheckboxInput';
import '../../styles.scss';

const Field = props => {
  const hideField = (() => {
    const { data, state } = props;
    const { input } = data;
    const { type } = input;

    if (type === 'checkbox') {
      if (state.question_3 === "1" || state.question_3 === "2") {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  })();

  const renderInput = () => {
    const { data, handleInputChange } = props;
    const { input } = data;
    const { type } = input;

    switch (type) {
      case 'reaction':
        return <ReactionInput input={input} handleChange={handleInputChange} />;

      case 'radio-row':
        return <RadioRowInput
          input={input}
          handleChange={handleInputChange}
        />;

      case 'radio-column':
        return <RadioColumnInput
          input={input}
          handleChange={handleInputChange}
        />;

      case 'checkbox':
        return <CheckboxInput
          options={input.options}
          handleChange={handleInputChange}
          reset={hideField}
        />;

      case 'text':
        return <TextInput handleChange={handleInputChange} />;

      default:
        return <TextInput handleChange={handleInputChange} />;
    }
  }

  const { title } = props.data;

  return <div className="row AnnouncementFormField" hidden={hideField}>
    <div className="col-12">
      <p>{title}</p>
      {renderInput()}
    </div>
  </div>
}

export default Field;