import React, { useEffect, useState } from "react";
import { FormFeedback, Modal, ModalHeader } from "reactstrap";
import {
  IconButton,
  CircularProgress,
  Button,
  Snackbar,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  Checkbox,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import api from "services/api";
import "../../../components/styles.scss";
import PropTypes from "prop-types";
import "./styles.scss";
import { withStyles } from "@material-ui/core/styles";
import { useForm, Controller } from "react-hook-form";
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "../../../../../../constants/Messages";
import { REQUIRED } from "../../../../../../constants/Validations";

const RadioSurveys = withStyles({
  colorSecondary: {
    color: "#989898",
    "&$checked": {
      color: "#3FA7E1",
    },
  },
  checked: {},
})(Radio);
const CbxSurveys = withStyles({
  colorSecondary: {
    color: "#989898",
    "&$checked": {
      color: "#3FA7E1",
    },
  },
  checked: {},
})(Checkbox);

const defaultAnswer3a = [];

const SurveyProcedureModal = (props) => {
  const [openSnack, setOpenSnack] = useState(false);
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null);
  const [answers, setAnswers] = useState({});
  const [procedureInfo, setProcedureInfo] = useState({});

  const { id, toggleOpen, isOpen, mode } = props;

  const {
    errors,
    control,
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
  } = useForm({
    defaultValues: mode == "view" ? answers : { question_3a: defaultAnswer3a },
  });
  const { question_3, question_3a } = watch(["question_3", "question_3a"]);
  const readOnly = mode == "view" ? true : false;
  const handleRequestCloseSnackbar = () => {
    setOpenSnack(false);
  };

  const submit = async (data) => {
    setLoading(true);
    //data.question_3a = data.question_3a;

    const response = await api.surveysProcedures.sendAnswersSurveyProcedure(
      id,
      JSON.stringify(data)
    );
    if (response.success) {
      setResponseMessage(response?.message);
      setOpenSnack(true);
      toggleOpen(true);
    } else {
      setResponseMessage(response.message || DEFAULT_REQUEST_ERROR_MESSAGE);
      setOpenSnack(true);
    }
    setLoading(false);
  };
  const getAnswers = async () => {
    setLoading(true);
    const response = await api.surveysProcedures.getIndividualAnswers(id);
    if (response.success) {
      setAnswers(response.data);
      reset(response.data);
    } else {
    }
    setLoading(false);
  };
  const getProcedureInfo = async () => {
    setLoading(true);
    const response = await api.surveysProcedures.getProcedureInfo(id);
    if (response.success) {
      setProcedureInfo(response.data);
    } else {
    }
    setLoading(false);
  };

  useEffect(() => {
    if (
      question_3 == "Me costó mucho trabajo" ||
      question_3 == "Tuve que esforzarme"
    ) {
      control.setValue("question_3a", []);
    }
  }, [question_3]);

  useEffect(() => {
    if (readOnly) {
      getAnswers();
    } else {
      getProcedureInfo();
    }
  }, []);

  return (
    <div>
      <Modal
        className="modal-box modal-box-mail SurveyProcedureModal"
        toggle={toggleOpen}
        isOpen={isOpen}
        style={{ zIndex: 2600 }}
        backdrop={"static"}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <ModalHeader className="modal-box-header text-white">
              <div style={{ maxWidth: "75%" }}>
                Encuesta de satisfacción - Mis trámites
              </div>
              <IconButton className="text-white" onClick={toggleOpen}>
                <CloseIcon />
              </IconButton>
            </ModalHeader>
            <form onSubmit={handleSubmit(submit)}>
              <div className="modal-box-content d-flex flex-column SurveyProcedureModal__Questions">
                <div className={"Question"}>
                  <p className={"Question--title"}>
                    {" "}
                    {`¿Cómo calificas la atención recibida por parte de ${readOnly
                        ? answers?.manager_name
                        : procedureInfo[0]?.manager_name
                      } que brindó la atención a tu solicitud con número de ticket ${readOnly
                        ? answers?.no_ticket
                        : procedureInfo[0]?.no_ticket
                      }? *`}
                  </p>
                  <div className={"Question--options reactions"}>
                    <FormControl>
                      <Controller
                        render={(props) => (
                          <RadioGroup row aria-label="question_1" {...props}>
                            <FormControlLabel
                              value="A"
                              control={
                                <RadioSurveys
                                  disabled={readOnly}
                                  icon={
                                    <IconButton>
                                      <i
                                        className={`icon-2-Mi-muro_Encuestas_resultados1`}
                                      />
                                    </IconButton>
                                  }
                                  checkedIcon={
                                    <IconButton>
                                      <i
                                        className={`icon-2-Mi-muro_Encuestas_resultados1 selected`}
                                      />
                                    </IconButton>
                                  }
                                />
                              }
                            />
                            <FormControlLabel
                              value="B"
                              control={
                                <RadioSurveys
                                  disabled={readOnly}
                                  icon={
                                    <IconButton>
                                      <i
                                        className={`icon-2-Mi-muro_Encuestas_resultados2`}
                                      />
                                    </IconButton>
                                  }
                                  checkedIcon={
                                    <IconButton>
                                      <i
                                        className={`icon-2-Mi-muro_Encuestas_resultados2 selected`}
                                      />
                                    </IconButton>
                                  }
                                />
                              }
                            />
                            <FormControlLabel
                              value="C"
                              control={
                                <RadioSurveys
                                  disabled={readOnly}
                                  icon={
                                    <IconButton>
                                      <i
                                        className={`icon-2-Mi-muro_Encuestas_resultados3`}
                                      />
                                    </IconButton>
                                  }
                                  checkedIcon={
                                    <IconButton>
                                      <i
                                        className={`icon-2-Mi-muro_Encuestas_resultados3 selected`}
                                      />
                                    </IconButton>
                                  }
                                />
                              }
                            />
                            <FormControlLabel
                              value="D"
                              control={
                                <RadioSurveys
                                  disabled={readOnly}
                                  icon={
                                    <IconButton>
                                      <i
                                        className={`icon-2-Mi-muro_Encuestas_resultados4`}
                                      />
                                    </IconButton>
                                  }
                                  checkedIcon={
                                    <IconButton>
                                      <i
                                        className={`icon-2-Mi-muro_Encuestas_resultados4 selected`}
                                      />
                                    </IconButton>
                                  }
                                />
                              }
                            />
                          </RadioGroup>
                        )}
                        disabled={readOnly}
                        type="radio"
                        name="question_1"
                        rules={{ required: REQUIRED }}
                        control={control}
                      />
                      {errors.question_1 && (
                        <FormFeedback
                          style={{ display: "block" }}
                          type="invalid"
                        >
                          {errors.question_1.message}
                        </FormFeedback>
                      )}
                    </FormControl>
                  </div>
                </div>
                <div className={"Question"}>
                  <p className={"Question--title"}>
                    {" "}
                    {
                      "¿Recomendarías el uso de Mi Muro a otros compañeros de trabajo para realizar sus trámites? *"
                    }
                  </p>
                  <div className={"Question--radio-options row"}>
                    <p className="col-2 text-center" style={{'overflow-wrap': 'break-word', padding: 0}}>Nunca lo recomendaría</p>
                    <div className="col-8" style={{display: 'flex', 'justify-content': 'center'}}>
                      <FormControl error={answers.question_2}>
                        <Controller
                          render={(props) => (
                            <RadioGroup row aria-label="question_2" className="RadioGroupCustomSurProc" {...props}>
                              <FormControlLabel
                                disabled={readOnly}
                                className={"option"}
                                value="1"
                                control={<RadioSurveys />}
                                label="1"
                                labelPlacement="top"
                              />
                              <FormControlLabel
                                disabled={readOnly}
                                className={"option"}
                                value="2"
                                control={<RadioSurveys />}
                                label="2"
                                labelPlacement="top"
                              />
                              <FormControlLabel
                                disabled={readOnly}
                                className={"option"}
                                value="3"
                                control={<RadioSurveys />}
                                label="3"
                                labelPlacement="top"
                              />
                              <FormControlLabel
                                disabled={readOnly}
                                className={"option"}
                                value="4"
                                control={<RadioSurveys />}
                                label="4"
                                labelPlacement="top"
                              />
                              <FormControlLabel
                                disabled={readOnly}
                                className={"option"}
                                value="5"
                                control={<RadioSurveys />}
                                label="5"
                                labelPlacement="top"
                              />
                              <FormControlLabel
                                disabled={readOnly}
                                className={"option"}
                                value="6"
                                control={<RadioSurveys />}
                                label="6"
                                labelPlacement="top"
                              />
                              <FormControlLabel
                                disabled={readOnly}
                                className={"option"}
                                value="7"
                                control={<RadioSurveys />}
                                label="7"
                                labelPlacement="top"
                              />
                              <FormControlLabel
                                disabled={readOnly}
                                className={"option"}
                                value="8"
                                control={<RadioSurveys />}
                                label="8"
                                labelPlacement="top"
                              />
                              <FormControlLabel
                                disabled={readOnly}
                                className={"option"}
                                value="9"
                                control={<RadioSurveys />}
                                label="9"
                                labelPlacement="top"
                              />
                              <FormControlLabel
                                disabled={readOnly}
                                className={"option"}
                                value="10"
                                control={<RadioSurveys />}
                                label="10"
                                labelPlacement="top"
                              />
                            </RadioGroup>
                          )}
                          type="radio"
                          name="question_2"
                          rules={{ required: REQUIRED }}
                          control={control}
                        />
                        {errors.question_2 && (
                          <FormFeedback
                            style={{ display: "block" }}
                            type="invalid"
                          >
                            {errors.question_2.message}
                          </FormFeedback>
                        )}
                      </FormControl>
                    </div>
                    <p className="col-2 text-center" style={{'overflow-wrap': 'break-word', padding: 0}}>Totalmente lo voy a recomendar</p>
                  </div>
                </div>
                <div className={"Question"}>
                  <p className={"Question--title"}>
                    {" "}
                    {
                      "¿Qué tanto esfuerzo tuviste que realizar para hacer tu trámite en Mi Muro? *"
                    }
                  </p>
                  <div className={"Question--radio-options"}>
                    <FormControl error={answers.question_3}>
                      <Controller
                        render={(props) => (
                          <RadioGroup aria-label="question_3" {...props}>
                            <FormControlLabel
                              disabled={readOnly}
                              value="Me costó mucho trabajo"
                              control={<RadioSurveys />}
                              label="Me costó mucho trabajo"
                            />
                            <FormControlLabel
                              disabled={readOnly}
                              value="Tuve que esforzarme"
                              control={<RadioSurveys />}
                              label="Tuve que esforzarme"
                            />
                            <FormControlLabel
                              disabled={readOnly}
                              value="Neutro"
                              control={<RadioSurveys />}
                              label="Neutro (ni mucho, ni poco)"
                            />
                            <FormControlLabel
                              disabled={readOnly}
                              value="Fue sencillo"
                              control={<RadioSurveys />}
                              label="Fue sencillo"
                            />
                            <FormControlLabel
                              disabled={readOnly}
                              value="Fue muy fácil de hacer"
                              control={<RadioSurveys />}
                              label="Fue muy fácil de hacer"
                            />
                          </RadioGroup>
                        )}
                        type="radio"
                        name="question_3"
                        rules={{ required: REQUIRED }}
                        control={control}
                      />
                      {errors.question_3 && (
                        <FormFeedback
                          style={{ display: "block" }}
                          type="invalid"
                        >
                          {errors.question_3.message}
                        </FormFeedback>
                      )}
                    </FormControl>
                  </div>
                </div>
                {(question_3 == "Me costó mucho trabajo" ||
                  question_3 == "Tuve que esforzarme") && (
                    <div className={"Question"}>
                      <p className={"Question--title"}>
                        {" "}
                        {
                          "Seleccione cuáles de los siguientes elementos le significaron mayor esfuerzo *"
                        }
                      </p>
                      <FormGroup>
                        <Controller
                          render={({ onChange, value }) => {
                            const handleChange = (evt) => {
                              if (evt.target.name === "other_answer_question_3") {
                                return;
                              }

                              if (evt.target.checked) {
                                value.push(evt.target.name);
                              } else {
                                value = value.filter(
                                  (item) => item != evt.target.name
                                );
                              }
                              onChange(value);
                            };
                            return (
                              <>
                                <FormControlLabel
                                  control={<CbxSurveys />}
                                  disabled={readOnly}
                                  onChange={handleChange}
                                  checked={value.includes("Acceso a Internet")}
                                  name="Acceso a Internet"
                                  label="Acceso a Internet"
                                />
                                <FormControlLabel
                                  control={<CbxSurveys />}
                                  disabled={readOnly}
                                  onChange={handleChange}
                                  checked={value.includes("Ingreso a Mi Muro")}
                                  name="Ingreso a Mi Muro"
                                  label="Ingreso a Mi Muro"
                                />
                                <FormControlLabel
                                  control={<CbxSurveys />}
                                  disabled={readOnly}
                                  onChange={handleChange}
                                  checked={value.includes("Uso de Mi Muro")}
                                  name="Uso de Mi Muro"
                                  label="Uso de Mi Muro"
                                />
                                <FormControlLabel
                                  control={<CbxSurveys />}
                                  disabled={readOnly}
                                  onChange={handleChange}
                                  checked={value.includes(
                                    "Solicitud de mi trámite"
                                  )}
                                  name="Solicitud de mi trámite"
                                  label="Solicitud de mi trámite"
                                />
                                <FormControlLabel
                                  control={<CbxSurveys />}
                                  disabled={readOnly}
                                  onChange={handleChange}
                                  checked={value.includes(
                                    "Seguimiento a mi solicitud de trámite"
                                  )}
                                  name="Seguimiento a mi solicitud de trámite"
                                  label="Seguimiento a mi solicitud de trámite"
                                />
                                <FormControlLabel
                                  control={<CbxSurveys />}
                                  disabled={readOnly}
                                  onChange={handleChange}
                                  checked={value.includes(
                                    "Comunicación con la o el gestor de trámite"
                                  )}
                                  name="Comunicación con la o el gestor de trámite"
                                  label="Comunicación con la o el gestor de mi trámite"
                                />
                                <span>
                                  <FormControlLabel
                                    control={<CbxSurveys />}
                                    disabled={readOnly}
                                    onChange={handleChange}
                                    checked={value.includes("Otro")}
                                    name="Otro"
                                    label="Otro:"
                                  />
                                  {question_3a.includes("Otro") && (
                                    <TextField
                                      spellCheck
                                      inputProps={{ readOnly: readOnly }}
                                      inputRef={register}
                                      name="other_answer_question_3"
                                      style={{ width: "87%" }}
                                    />
                                  )}
                                </span>
                              </>
                            );
                          }}
                          type="checkbox"
                          name="question_3a"
                          control={control}
                          rules={{ required: REQUIRED }}
                        />
                        {errors.question_3a && (
                          <FormFeedback
                            style={{ display: "block" }}
                            type="invalid"
                          >
                            {errors.question_3.message}
                          </FormFeedback>
                        )}
                      </FormGroup>
                    </div>
                  )}
                <div className={"Question"}>
                  <p className={"Question--title"}>
                    {" "}
                    {
                      "¿Tienes algún comentario que nos permita mejorar la atención a la solicitud de trámites a través de Mi Muro?"
                    }
                  </p>
                  <TextField
                    spellCheck
                    name="question_4"
                    placeholder={"Tu respuesta"}
                    inputProps={{ readOnly: readOnly }}
                    inputRef={register}
                    fullWidth
                  />
                </div>
              </div>

              {!readOnly && (
                <div className="modal-box-footer">
                  <div className="container">
                    <div className="row">
                      <div className="p-0 text-center">
                        <Button
                          type={"submit"}
                          variant="contained"
                          size="small"
                          className="mi_muro-btn_cyan"
                        >
                          Enviar encuesta
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </>
        )}
      </Modal>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={15000}
        open={openSnack}
        onClose={handleRequestCloseSnackbar}
        ContentProps={{
          "aria-describedby": "message-id",
          className: "bg-danger",
        }}
        message={<span id="message-id">{responseMessage}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleRequestCloseSnackbar}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </div>
  );
};

SurveyProcedureModal.propTypes = {
  toggleOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  handleSubmit: PropTypes.func,
  mode: PropTypes.string,
  id: PropTypes.number,
};

export default SurveyProcedureModal;
