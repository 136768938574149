import React, { useState } from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import '../../styles.scss';

const RadioRowInput = props => {
  const [value, setValue] = useState(null);

  const onChangeRadio = ev => {
    const { handleChange } = props;
    const { value: val } = ev.target;
    handleChange(val);
    setValue(val);
  }

  const { input = {} } = props;
  const {
    options = [],
    text_left = '',
    text_right = ''
  } = input;

  return <div className="row AnnounFormRadioField">
    <div className="col-12 TextInTop">
      <p className="AFRadioLegend">{text_left}</p>
      <p className="AFRadioLegend">{text_right}</p>
    </div>

    <p className="col-2 TextInRow AFRadioLegend">{text_left}</p>
    <div className="col-8 AFRadioGroupArea">
      <FormControl component="fieldset">
        <RadioGroup
          className="AFRadioGroup"
          value={value}
          onChange={onChangeRadio}
          row
        >
          {options.map(({ value: val, label }, idx) => (
            <FormControlLabel
              key={idx}
              value={String(val)}
              control={<Radio />}
              label={label}
              labelPlacement="top"
            />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
    <p className="col-2 TextInRow AFRadioLegend">{text_right}</p>
  </div>;
}

export default RadioRowInput;