import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GridImage from "./GridImage"
import { VideoContainer } from "app/routes/Help/routes/List/VideoModal";
import baseUrl from "constants/Api";
import "./styles.scss";

const TutorialsModal = (props) => {
  const [isCarouselOpen, setIsCarouselOpen] = useState(false);
  const [slideNumber, setSlideNumber] = useState(0);
  const { isOpen, handleClose, mediaList } = props;

  const onSelectSlide = (slide) => {
    setSlideNumber(slide);
    setIsCarouselOpen(true);
  }

  const closeModal = () => {
    setSlideNumber(0);
    setIsCarouselOpen(false);
    handleClose();
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={closeModal}
    >
      <ModalHeader toggle={closeModal}>
        Centro de ayuda
      </ModalHeader>
      <ModalBody>
        {
          isCarouselOpen
          ? (
            <Carousel
              mediaList={mediaList}
              slide={slideNumber}
            />
          )
          : (
            mediaList.length
            ? <GridImage mediaList={mediaList} handleClick={onSelectSlide} />
            : <h3>No hay tutoriales disponibles</h3>
          )
        }
      </ModalBody>
      <ModalFooter className="justify-content-center tutorials-modal-footer">
        <p><b>Horario de atención:</b> Lunes a Viernes de 09:00 am a 05:00 pm.</p>
        <p><b>Teléfono de contacto:</b> 33-30-30-75-05 Opción 4</p>
      </ModalFooter>
    </Modal>
  );
};

function Carousel(props) {
  const settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "slides",
    lazyLoad: "ondemand",
    initialSlide: props.slide || 0,
  };
  return (
    <Slider {...settings}>
      {props.mediaList.map((media, index) => (
        <div key={index} className="text-center px-5">
          {media.file_type === "Video" ? (
            <VideoContainer url={`${baseUrl}/${media.file}`} externalUrl={media.url} />
          ) : (
            <img alt="media" src={`${baseUrl}/${media.file}`} />
          )}
          <div className="p-3" style={{minHeight: "90px"}}>
            <p className="break-text-two-lines">{media.title || "Sin título"}</p>
          </div>
        </div>
      ))}
    </Slider>
  );
}

export default TutorialsModal;