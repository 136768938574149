export const GENERAL_ADMIN_ROLE = "Responsable de Red Social";
export const GROUP_ADMIN_ROLE = "Administrador de grupo de G-Suite";
export const USER_ROLE = "Usuario final";
export const RESPONSIBLE_ROLE = "Responsable de trámite";
export const PROCEDURE_AREA_ADMIN = "Titular de dirección de área";
export const USER_MANAGEMENT_ROLE = "Administrador general DTI";
export const EXPEDIENTE_VIEWER_ROLE = "Visualizador de mi expediente";
export const RECORD_ADMINISTRATOR_ROLE = "Administrador de mi expediente";
export const RECORD_VALIDATOR_ROLE = "Validador de mi expediente";
export const RECORD_TRACKING_LINK = "Enlace de seguimiento a expediente"
export const REGULATORY_VALIDATOR = "Validador Normativo";
export const SECRETARY_HEAD = "Titular de la secretaría";
export const SUBSECRETARY_HEAD = "Titular de la subsecretaría";
export const GENERAL_DIRECTION_HEAD = "Titular de dirección general";
export const NOTIFICATION_RESPONSIBLE = "Responsable de Notificaciones";
export const MANAGER_ROLE = "Gestor de trámites";
export const HEAD_OF_DEPARTMENT = "Jefe de departamento";
export const PUBLIC_SERVER_OPERATIONAL = "Servidor público operativo";
export const UNIT_HOLDER = "Titular de Unidad";
export const OPERATIVE_PROCEDURE_RESPONSIBLE_ROLE = "Responsable Operativo de Trámites";