import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { changeHeaderTitle } from "actions";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import {
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
  INSIDE_THE_HEADER
} from "constants/ActionTypes";
import AppNotification from "../AppNotification/index";
import CardHeader from "components/dashboard/Common/CardHeader/index";
import {
  switchLanguage,
  toggleCollapsedNav,
  handleSurveyAnnouncementModal,
  handleSurveyProcedureModal,
  handleSurveyRecordModal
} from "actions";
import IntlMessages from "util/IntlMessages";
import Menu from "components/TopNav/Menu";
import UserInfo from "components/UserInfo";
import api from 'services/api'
import "../../styles/custom_styles.scss";
import SurveyRecordsModal from "app/routes/Surveys/routes/Records/components/SurveyRecordsModal";
import AnnouncementForm from "app/routes/Surveys/routes/Announcements/components/AnnouncementForm";
import SurveyProcedureModal from "../../app/routes/Surveys/routes/SurveysProcedures/components/SurveyProceduresModal";

class Header extends React.Component {
  onAppNotificationSelect = () => {
    this.setState({
      appNotification: !this.state.appNotification
    });
  };
  onSearchBoxSelect = () => {
    this.setState({
      searchBox: !this.state.searchBox
    });
  };
  onToggleCollapsedNav = () => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };

  constructor() {
    super();
    this.state = {
      anchorEl: undefined,
      searchBox: false,
      searchText: "",
      userInfo: false,
      appNotification: false,
      notifications: [],
    };
    this.interval = setInterval(this.getNotifications.bind(this), 120000);
    this.getNotifications();
  }

  updateSectionTitle = (pathname) => {
    const { changeHeaderTitle } = this.props;
    try {
      const section = pathname.split('/')[2];
      if (section === 'blog') { changeHeaderTitle('Mi red social'); }
      else if (section === 'profile') { changeHeaderTitle('Mi expediente'); }
      else if (section === 'procedures') { changeHeaderTitle('Mis trámites'); }
      else if (section === 'notifications') { changeHeaderTitle('Mis notificaciones'); }
      else if (section === 'surveys') { changeHeaderTitle('Encuestas'); }
      else if (section === 'users') { changeHeaderTitle('Usuarios'); }
      else if (section === 'day-absence') { changeHeaderTitle('Días inhábiles'); }
      else if (section === 'help-content') { changeHeaderTitle('Tutoriales'); }
      else { changeHeaderTitle('Mi muro'); }
    } catch (error) {
      changeHeaderTitle('Mi muro Error');
    }
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen(location => {
      this.updateSectionTitle(location.pathname || '');
    });
  }

  componentDidMount() {
    this.updateSectionTitle(this.props?.history?.location?.pathname || '');
  }

  componentWillUnmount() {
    this.unlisten();
    clearInterval(this.interval);
    clearInterval(this.surveyInterval);
  }

  updateSearchText(evt) {
    this.setState({
      searchText: evt.target.value
    });
  }

  async getNotifications() {
    const response = await api.notifications.getUnSeenNotifications();
    if (response.success) {
      this.setState({
        notifications: response.notifications?.data
      });
    }
  }

  async deleteNotification(id) {
    const { notifications } = this.state;
    notifications.some((notification, index) => {
      if (notification.id === id) {
        notifications.splice(index, 1);
        return true;
      }
      return false;
    });
    await api.notifications.viewedNotification(id);
    this.setState({
      notifications,
      appNotification: false
    });
  }

  render() {
    const {
      drawerType,
      navigationStyle,
      horizontalNavPosition,
      surveyAnnouncementModal,
      handleSurveyAnnouncementModal,
      surveyProcedureModal,
      handleSurveyProcedureModal,
      surveyRecordModal,
      handleSurveyRecordModal,
    } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? "d-block d-xl-none"
      : drawerType.includes(COLLAPSED_DRAWER)
        ? "d-block"
        : "d-none";
    const { notifications } = this.state;

    return (
      <AppBar
        className={`app-main-header app-header mi_muro-bg_magenta ${navigationStyle === HORIZONTAL_NAVIGATION &&
          horizontalNavPosition === BELOW_THE_HEADER
          ? "app-main-header-top"
          : ""
          }`}
      >
        <Toolbar disableGutters={false}>
          {navigationStyle === HORIZONTAL_NAVIGATION ? (
            <div
              className="d-block d-md-none pointer mr-3"
              onClick={this.onToggleCollapsedNav}
            >
              <span className="jr-menu-icon">
                <span className="menu-icon" />
              </span>
            </div>
          ) : (
            <IconButton
              className={`jr-menu-icon mr-3 ${drawerStyle}`}
              aria-label="Menu"
              onClick={this.onToggleCollapsedNav}
            >
              <span className="menu-icon" style={{ backgroundColor: 'white' }} />
            </IconButton>
          )}

          <h3 className="mi_muro mi_muro-title_section_header" style={{ margin: '0px', color: 'white' }}>{this.props.titleSection}</h3>

          <ul className="header-notifications list-inline ml-auto">
            <li className="list-inline-item app-tour">
              <Dropdown
                className="quick-menu"
                isOpen={this.state.appNotification}
                toggle={this.onAppNotificationSelect.bind(this)}
              >
                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown"
                >
                  <IconButton className="icon-btn">
                    <i
                      // data-count={
                      //   notifications.length > 15 ? "15+" : notifications.length
                      // }
                      style={{ color: "#FFF", fontSize: '30px', fontWeight: 'normal' }}
                      className={`zmdi zmdi-notifications-none ${notifications.length ? "alert-icon" : ""
                        } `}
                    />
                  </IconButton>
                </DropdownToggle>

                <DropdownMenu right>
                  <CardHeader
                    styleName="align-items-center notifications-bell__title"
                    heading={<IntlMessages id="appNotification.title" />}
                  />
                  <AppNotification
                    notifications={notifications}
                    history={this.props.history}
                    deleteNotification={this.deleteNotification.bind(this)}
                  />
                </DropdownMenu>
              </Dropdown>
            </li>
          </ul>

          <span
            className="d-inline-block ml-1 mr-1"
            style={{ backgroundColor: "#CCCCCC", width: "1px" }}
          >
            &nbsp;
          </span>

          <UserInfo history={this.props.history} />
          {navigationStyle === HORIZONTAL_NAVIGATION &&
            horizontalNavPosition === INSIDE_THE_HEADER && <Menu />}
          <div className="ellipse-shape" />
        </Toolbar>
        {
          surveyRecordModal.open &&
          <SurveyRecordsModal
            isOpen={surveyRecordModal.open}
            toggleOpen={reload => {
              handleSurveyRecordModal({ open: false, data: {} });
              if (reload === true) {
                window.location.reload();
              }
            }}
            callback={surveyRecordModal.callback}
            readOnly={surveyRecordModal.readOnly}
            sendRenotify={surveyRecordModal.renotify}
            surveyRecord={surveyRecordModal.data}
          />
        }
        {
          surveyAnnouncementModal.open &&
          <AnnouncementForm
            announcement_id={surveyAnnouncementModal.id}
            isOpen={surveyAnnouncementModal.open}
            toggleOpen={reload => {
              handleSurveyAnnouncementModal({ open: false, id: null });
              if (reload === true) {
                window.location.reload();
              }
            }}
          />
        }
        {
          surveyProcedureModal.open &&
          <SurveyProcedureModal
            id={surveyProcedureModal.id}
            mode={surveyProcedureModal.mode}
            isOpen={surveyProcedureModal.open}
            toggleOpen={reload => {
              handleSurveyProcedureModal({ open: false, id: null, mode: '' });
              if (reload === true) {
                window.location.reload();
              }
            }} />
        }
      </AppBar>
    );
  }
}

const mapStateToProps = ({ settings, surveys, header }) => {
  const {
    drawerType,
    locale,
    navigationStyle,
    horizontalNavPosition
  } = settings;
  const { surveyAnnouncementModal, surveyProcedureModal, surveyRecordModal } = surveys;
  const { title } = header;
  return {
    drawerType,
    locale,
    navigationStyle,
    horizontalNavPosition,
    surveyAnnouncementModal,
    surveyProcedureModal,
    surveyRecordModal,
    titleSection: title,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    toggleCollapsedNav,
    switchLanguage,
    handleSurveyAnnouncementModal,
    handleSurveyProcedureModal,
    handleSurveyRecordModal,
    changeHeaderTitle,
  })(Header)
);
