import React, { useState, useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import api from "services/api";
import "styles/custom_styles.scss";

const QRData = props => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [qr, setQr] = useState({});

  const searchData = async (qrId) => {
    setLoading(true);
    const response = await api.appointments.getQRData(qrId);
    if (response.success) {
      setQr(response.qr);
    } else {
      setError(true);
    }
    setLoading(false);
  }

  useEffect(() => {
    const { id } = props.match.params;
    searchData(id);
  }, []);

  return (
    <div className="qr-view">
      <div className="qr-view-header">
        <img
          className="ml-4"
          src={require("assets/images/SEJ_Logo_Educación_Logo_Educación.png")}
          alt="Administración"
          title="Administración"
          style={{ objectFit: "contain", height: "100%" }}
        />
      </div>

      <div className="qr-view-container">
        <div className="row">
          <div className="col-12">
            <div className="mb-3">
              <span className="qr-view-title d-flex">
                <i className="zmdi icon-SEJ_Reportes mr-2" />
                <b>Información de la cita</b>
              </span>
            </div>
          </div>
        </div>

        {loading ? (
          <div className="loader-view text-center w-100">
            <CircularProgress />
          </div>
        ) :
          error ? <p>Ningún resultado encontrado</p> :
            <>
              <div className="row">
                <div className="col-12 field-qr">
                  <p className="field-qr-label">Nombre del trámite</p>
                  <p className="field-qr-value">{qr.procedureName ?? '-'}</p>
                </div>
              </div>

              <div className="row">
                <div className="col-12 field-qr">
                  <p className="field-qr-label">Fecha y hora de la cita</p>
                  <p className="field-qr-value">{qr?.appointment?.date ?? '-'} {qr?.appointment?.time ?? '-'}</p>
                </div>
              </div>

              <div className="row">
                <div className="col-12 field-qr">
                  <p className="field-qr-label">Nombre del solicitante</p>
                  <p className="field-qr-value">{qr.userName ?? '-'}</p>
                </div>
              </div>

              <div className="row">
                <div className="col-12 field-qr">
                  <p className="field-qr-label">CURP del solicitante</p>
                  <p className="field-qr-value">{qr.userCurp ?? '-'}</p>
                </div>
              </div>

              <div className="row">
                <div className="col-12 field-qr">
                  <p className="field-qr-label">Cuenta institucional del solicitante</p>
                  <p className="field-qr-value">{qr.userEmail ?? '-'}</p>
                </div>
              </div>

              <div className="row">
                <div className="col-12 field-qr">
                  <p className="field-qr-label">Departamento que atiende</p>
                  <p className="field-qr-value">{qr.userDepartment ?? '-'}</p>
                </div>
              </div>

              <div className="row">
                <div className="col-12 field-qr">
                  <p className="field-qr-label">Gestor a cargo del ticket</p>
                  <p className="field-qr-value">{qr.ResponsibleName ?? '-'}</p>
                </div>
              </div>

              <div className="row">
                <div className="col-12 field-qr">
                  <p className="field-qr-label">Domicilio de atención</p>
                  <p className="field-qr-value">{qr.appointmentPlace}</p>
                </div>
              </div>
            </>}
      </div>
    </div>
  );
}

export default QRData;
