import React, { useEffect, useState } from 'react'
import { useLocation, matchPath } from "react-router-dom"
import Directory from './Directory'
import { systemRoutes } from "constants/selects";
import TutorialsModal from './TutorialsModal';
import api from "services/api";

const routes = systemRoutes.map(route => route.id);

const DeskHelp = () => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [tutorials, setTutorials] = useState([]);
    const location = useLocation();

    const handleDirectoryClick = event => {
        // setOpenDirectory(true)
        setAnchorEl(event.currentTarget);
    }

    const handleDialogClose = () => {
        // setOpenDirectory(false)
        setAnchorEl(null);
    }

    const getTutorials = async (route) => {
        setTutorials([]);
        const response = await api.admin.getHelpContentByRoute(route);
        if (response.success) {
            response.tutorials.map(tutorial => {
                tutorial.thumbnail = tutorial.thumbnail ? tutorial.thumbnail?.file : null;
            })
            setTutorials(response.tutorials)
        }
    }

    const getCurrentPath = () => {
        for (const route of routes) {
            const match = matchPath(location.pathname, {
                path: route,
                exact: false,
                strict: false
              });
            if (match) {
                getTutorials(route);
                return
            }
        }
    }

    useEffect(getCurrentPath, [location]);


   
    return (
        <>
            <div className={`help-desk__btn-directory ${anchorEl ? 'hidden' : ''}`}
                onClick={handleDirectoryClick}
            >
                {/* <i className="zmdi zmdi-phone zmdi-hc-lg mr-2"></i> */}
                <img
                    height={15}
                    src={require(`assets/images/Eventos_ayuda.svg`)}
                    style={{ marginRight: "5px", fontSize: "12px" }}
                    className="mr-2"
                />
                ¿Necesitas ayuda?
            </div>
            {/* <Directory  
                // open = {openDirectory}
                anchorEl = {anchorEl}
                handleClose = {handleDialogClose}
            /> */}
            
            <TutorialsModal mediaList={tutorials} isOpen={!!anchorEl} handleClose={handleDialogClose} />

        </>
    )
}

export default DeskHelp



 