import { sendRequest } from "util/utils";
import storage from "services/storage";

const createPostPublicBlog = async data => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/blogs/general`;
  const request = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: token,
      token: storage.getSSID(),
    },
    body: data
  };
  return await sendRequest(route, request);
};

const createPostPrivateBlog = async data => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/blogs/area`;
  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
      token: storage.getSSID(),
    },
    body: JSON.stringify(data)
  };
  return await sendRequest(route, request);
};

const editPostPublicBlog = async (id, data) => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/blogs/${id}`;
  const request = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: token,
      token: storage.getSSID(),
    },
    body: data
  };
  return await sendRequest(route, request);
};

const editPostPrivateBlog = async (id, data) => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/blogs/${id}`;
  const request = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
      token: storage.getSSID(),
    },
    body: JSON.stringify(data)
  };
  return await sendRequest(route, request);
};

const deletePost = async blogId => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/blogs/${blogId}`;
  const request = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
      token: storage.getSSID(),
    }
  };
  return await sendRequest(route, request);
};

const addComment = async (data, blogId) => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/blogs/${blogId}/comment`;
  const request = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: token,
      token: storage.getSSID(),
    },
    body: data
  };
  return await sendRequest(route, request);
};

const editComment = async (data, commentId) => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/blogs/comments/${commentId}`;
  const request = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: token,
      token: storage.getSSID(),
    },
    body: data
  };
  return await sendRequest(route, request);
};

const deleteComment = async commentId => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/blogs/comments/${commentId}`;
  const request = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
      token: storage.getSSID(),
    }
  };
  return await sendRequest(route, request);
};

const LikePost = async blogId => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/blogs/${blogId}/like`;
  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
      token: storage.getSSID(),
    }
  };
  return await sendRequest(route, request);
};

const getPostsList = async (blog = "general", filter = null, page) => {
  const token = `Bearer ${storage.getToken()}`;
  let route = `/blogs/${blog}/latest?page=${page}`;
  if (filter?.start_date) {
    route += `&start_date=${filter.start_date}&end_date=${filter.end_date}`;
  }
  if (filter?.search) {
    route += `&term=${filter.search}`;
  }
  if (filter?.area_id) {
    route += `&area_id=${filter.area_id}`;
  }
  if(filter?.order){
    route += `&order=${filter.order}`;
  }
  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
      token: storage.getSSID(),
    }
  };
  return await sendRequest(route, request);
};

const getPost = async (blog_id) => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/blogs/${blog_id}`;
  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
      token: storage.getSSID(),
    },
  };
  return await sendRequest(route, request);
};

const getAreasList = async () => {
  const token = `Bearer ${storage.getToken()}`;
  let route = `/blogs/active-posts-areas`;
  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
      token: storage.getSSID(),
    }
  };
  return await sendRequest(route, request);
};

const anchorPost = async (postId, data) => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/pin_post/${postId}`;
  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
      token: storage.getSSID(),
    },
    body: JSON.stringify(data ? data : {})
  };
  return await sendRequest(route, request);
};

const unAnchorPost = async postId => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/unpin_post/${postId}`;
  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
      token: storage.getSSID(),
    }
  };
  return await sendRequest(route, request);
};

const showPostComments = async (data, blogId) => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/blogs/${blogId}/comments`;
  const request = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
      token: storage.getSSID(),
    },
    body: JSON.stringify(data)
  };
  return await sendRequest(route, request);
};

const replyComment = async (data, commentId) => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/blogs/comments/${commentId}`;
  const request = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: token,
      token: storage.getSSID(),
    },
    body: data
  };
  return await sendRequest(route, request);
};

const getAnnouncements = async page => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/blogs/announcements/index?page=${page}`,
    request = {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const saveAdendum = async ({ postId, data }) => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/blogs/${postId}/addemdums`;
  const request = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: token,
      token: storage.getSSID(),
    },
    body: data
  };
  return await sendRequest(route, request);
};

const getStatistics = async page => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/announcements/statistics?page=${page}`;
  const request = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: token,
      token: storage.getSSID(),
    }
  };
  return await sendRequest(route, request);
};

const getWorkAreas = async () => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/tagged_areas`;
  const request = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: token,
      token: storage.getSSID(),
    }
  };
  return await sendRequest(route, request);
}

export default {
  addComment,
  editComment,
  deleteComment,
  createPostPublicBlog,
  createPostPrivateBlog,
  editPostPublicBlog,
  editPostPrivateBlog,
  deletePost,
  LikePost,
  getPostsList,
  getPost,
  showPostComments,
  anchorPost,
  unAnchorPost,
  replyComment,
  getAnnouncements,
  saveAdendum,
  getStatistics,
  getWorkAreas,
  getAreasList
};
