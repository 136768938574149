import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import { Box } from '@material-ui/core';


const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
        borderRadius: '8px',
        boxShadow: 'rgb(0 0 0 / 16%) 0px 5px 40px',
        width: '260px'
    },
})(props => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        {...props}
    />
));


const Directory = (props) => {
    const { anchorEl, handleClose } = props

    return (
        <Box >

            <StyledMenu className="help-desk__directory-menu" id="customized-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <div className="directory-menu__header">
                    <span>Información de contacto</span>
                    <i className="zmdi zmdi-close" onClick={handleClose}></i>

                </div>
                <div className="directory-menu__body">
                    <div className="time">
                        <span className="subTitle">
                            <i className="zmdi zmdi-time"></i>
                            Horario de atención
                        </span>
                        <span className="text">Lunes a Viernes</span>
                        <span className="text">de 09:00 am a 03:00 pm.</span>

                    </div>
                    <div className="phones">
                        <span className="subTitle">
                            <i className="zmdi zmdi-phone"></i>
                            Teléfono de contacto
                        </span>
                        <span className="text">33-30-30-75-05 Opción 4</span>
                    </div>

                </div>
            </StyledMenu>
        </Box>
    );
}

export default Directory
