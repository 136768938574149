import React, { useEffect, useState } from "react";
import {
  FormGroup,
  FormControl,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@material-ui/core";
import '../../styles.scss';

const CheckboxInput = props => {
  const [selecteds, setSelecteds] = useState({});
  const { options = [] } = props;

  const onChangeCheckbox = ev => {
    const { value, checked } = ev.target;
    const { handleChange } = props;
    const snapshotSelecteds = { ...selecteds };

    snapshotSelecteds[value] = checked;

    const { otherContent = '', ...collection } = snapshotSelecteds;
    if (value === 'other') {
      handleChange({ ...collection, other: checked ? otherContent : false });
    } else {
      if (snapshotSelecteds.other) {
        handleChange({ ...collection, other: otherContent });
      } else {
        handleChange(collection);
      }
    }
    setSelecteds(snapshotSelecteds);
  }

  const onChangeOther = ev => {
    const { value } = ev.target;
    const snapshotSelecteds = { ...selecteds };

    if (snapshotSelecteds.other === true) {
      const { handleChange } = props;
      const { otherContent, ...collection } = snapshotSelecteds;
      handleChange({ ...collection, other: value });
    }

    snapshotSelecteds.otherContent = value;
    setSelecteds(snapshotSelecteds);
  }

  const renderOther = key => {
    return <div key={key} className="OherOption">
      <FormControlLabel
        control={<Checkbox
          onChange={onChangeCheckbox}
          name={'other'}
          value={'other'}
          checked={selecteds.other === true}
        />}
        label={'Otro:'}
      />
      <TextField
        className="InputForm"
        onChange={onChangeOther}
        margin="normal"
        fullWidth
        spellCheck="true"
        value={selecteds.otherContent || ''}
      />
    </div>
  }

  useEffect(() => {
    const { reset } = props;
    if (reset) {
      setSelecteds({});
    }
  }, [props.reset]);


  return true ? <FormControl component="fieldset">
    <FormGroup className="AnnounFormCheckboxField">
      {options.map(({ value, label }, idx) => (
        value ?
          <FormControlLabel
            key={idx}
            label={label}
            control={<Checkbox
              onChange={onChangeCheckbox}
              name={String(value)}
              value={value}
              checked={selecteds[value] === true}
            />}
          /> : renderOther(idx)
      ))}
    </FormGroup>
  </FormControl> : <></>
}

export default CheckboxInput;