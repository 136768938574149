export const icons = {
  0: { icon: " icon-2-Mi-muro_Encuestas", color: "#3fa7e1" },
  1: { icon: "avisos", color: "#7E57C5" },
  2: { icon: "envio-solicitud", color: "#C60B4C" },
  3: { icon: "rechazo", color: "#C60B4C" },
  4: { icon: "solicitud-actualizada", color: "#C60B4C" },
  5: { icon: "aprobacion", color: "#C60B4C" },
  6: { icon: "actualizacion-pregunta", color: "#7FB91E" },
  7: { icon: "aut-normativa-tecnica", color: "#7FB91E" },
  8: { icon: "rechazo", color: "#7FB91E" },
  9: { icon: "aprobacion", color: "#7FB91E" },
  10: { icon: "rechazo", color: "#7FB91E" },
  11: { icon: "aut-normativa-tecnica", color: "#7FB91E" },
  12: { icon: "aprobacion", color: "#7FB91E" },
  13: { icon: "por-inhabilitar", color: "#7FB91E" },
  14: { icon: "no-inhabilitada", color: "#7FB91E" },
  15: { icon: "inhabilitada", color: "#7FB91E" },
  16: { icon: "no-inhabilitada", color: "#7FB91E" },
  17: { icon: "por-inhabilitar", color: "#7FB91E" },
  18: { icon: "inhabilitada", color: "#7FB91E" },
  19: { icon: "respuesta-comentario", color: "#E93251" },
  20: { icon: "seguimiento-de-tramite", color: "#7FB91E" },
  21: { icon: "tarea-realizada", color: "#7FB91E" },
  22: { icon: "aut-normativa-tecnica", color: "#7FB91E" },
  23: { icon: "tarea-realizada", color: "#7FB91E" },
  24: { icon: "aprobacion", color: "#C60B4C" },
  25: { icon: "expediente-digital", color: "#A61F4F" },
  26: { icon: "seguimiento-de-tramite", color: "#A61F4F" },
  27: { icon: "respuesta-comentario", color: "#7B868C" },
  30: { icon: "Encuestas", color: "#3fa7e1"},
  31: { icon: "mensaje_priorizado", color: "#7FB91E"},
  32: { icon: "encuestas", color: "#3fa7e1"},
  33: { icon: "avisos", color: "#FEC62E"}
};
