import { sendRequest } from "util/utils";
import storage from "services/storage";

const createConnection = async data => {
    const token = `Bearer ${storage.getToken()}`;
    const route = `/connections`;
    const request = {
        method: "POST",
        headers: {
            Accept: "application/json",
            Authorization: token,
            token: storage.getSSID(),
        },
        body: data
    };
    return await sendRequest(route, request);
};

const getConnections = async (page) => {
    const token = `Bearer ${storage.getToken()}`;
    const route = `/connections`;
    const request = {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: token,
            token: storage.getSSID(),
        },
    };
    return await sendRequest(route, request);
};

const getConnection = async id => {
    const token = `Bearer ${storage.getToken()}`;
    const route = `/connections/${id}`;
    const request = {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: token,
            token: storage.getSSID(),
        },
    };
    return await sendRequest(route, request);
};

const updateConnection = async (data, id) => {
    const token = `Bearer ${storage.getToken()}`;
    const route = `/connections/${id}`;
    const request = {
        method: "POST",
        headers: {
            Accept: "application/json",
            Authorization: token,
            token: storage.getSSID(),
        },
        body: data
    };
    return await sendRequest(route, request);
};

const deleteConnection = async (id) => {
    const token = `Bearer ${storage.getToken()}`;
    const route = `/connections/${id}`;
    const request = {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            Authorization: token,
            token: storage.getSSID(),
        }
    };
    return await sendRequest(route, request);
};

export default {
    updateConnection,
    getConnection,
    getConnections,
    createConnection,
    deleteConnection
}