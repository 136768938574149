import storage from "services/storage";
import { sendRequest } from "util/utils";

const saveHelpContent = async (data) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/tutorials`,
    request = {
      method: "POST",
      headers: {
        Accept: 'application/json',
        Authorization: token,
        token: storage.getSSID(),
      },
    body: data
    };
  return await sendRequest(route, request);
};

const getHelpContentList = async (page) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/tutorials?page=${page}`,
    request = {
      method: "GET",
      headers: {
        Accept: 'application/json',
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

const getHelpContentDetail = async (id) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/tutorials/${id}`,
    request = {
      method: "GET",
      headers: {
        Accept: 'application/json',
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

const updateHelpContent = async (id, data) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/tutorials/${id}`,
    request = {
      method: "POST",
      headers: {
        Accept: 'application/json',
        Authorization: token,
        token: storage.getSSID(),
      },
    body: data
    };
  return await sendRequest(route, request);
};

const deleteTutorial = async (id) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/tutorials/${id}`,
    request = {
      method: "DELETE",
      headers: {
        Accept: 'application/json',
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
}

const getHelpContentByRoute = async (routeName) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/tutorials-by-filters?pageName=${routeName}`,
    request = {
      method: "GET",
      headers: {
        Accept: 'application/json',
        Authorization: token,
        token: storage.getSSID(),
      },
      // body: JSON.stringify({pageNames: [routeName]}),
    };
  return await sendRequest(route, request);
}

const getCategoriesList= async (page) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/categories?page=${page}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};
const getCategoriesSearch = async (search, page) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/categories?search=${search}&page=${page}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

const getCategoryDetail = async (id) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/categories/${id}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

const updateCategory = async (id,data) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/categories/${id}`,
    request = {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // Accept: "application/x-www-form-urlencoded",
        Authorization: token,
        token: storage.getSSID(),
      },
      body: data
    };
  return await sendRequest(route, request);
};

const createCategory = async (data) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/categories`,
    request = {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
      body: data
    };
  return await sendRequest(route, request);
};

const deleteCategory = async (id) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/categories/${id}`,
    request = {
      method: "DELETE",
      headers: {
        Accept: 'application/json',
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
}

export default {
  saveHelpContent,
  getHelpContentList,
  getHelpContentDetail,
  updateHelpContent,
  deleteTutorial,
  getHelpContentByRoute,
  getCategoriesList,
  getCategoriesSearch,
  getCategoryDetail,
  updateCategory,
  createCategory,
  deleteCategory
};