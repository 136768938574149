import { HEADER_TITLE } from "../constants/ActionTypes";

const initialState = {
    title: "Mi muro",
}

const header = (state = initialState, action) => {
    switch (action.type) {
        case HEADER_TITLE:
            return {
                ...state,
                title: action.payload,
            };
        default:
            return state;
    }
}

export default header;
