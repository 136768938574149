import React, { useEffect, useState } from "react";
import { FormFeedback, Modal, ModalHeader } from "reactstrap";
import {
  IconButton,
  CircularProgress,
  Button,
  Snackbar,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  Checkbox,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Controller, useForm } from "react-hook-form";
import { REQUIRED } from "constants/Validations";
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "constants/Messages";
import api from "services/api";
import utils from "util/utils";
import "../../../components/styles.scss";
import PropTypes from "prop-types";
import "./styles.scss";
import { withStyles } from "@material-ui/core/styles";

const RadioSurveys = withStyles({
  colorSecondary: {
    color: "#989898",
    "&$checked": {
      color: "#3FA7E1",
    },
  },
  checked: {},
})(Radio);

const CbxSurveys = withStyles({
  colorSecondary: {
    color: "#989898",
    "&$checked": {
      color: "#3FA7E1",
    },
  },
  checked: {},
})(Checkbox);

const defaultAnswer3a = [false, false, false, false, false, false, false, ""];

const SurveyRecordsModal = (props) => {
  const {
    toggleOpen,
    callback,
    isOpen,
    readOnly,
    sendRenotify,
    surveyRecord,
  } = props;
  const [openSnack, setOpenSnack] = useState(false);
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null);
  const [answer_3a, setAnswer3a] = useState(
    surveyRecord?.answer_3a || utils.cloneObject(defaultAnswer3a)
  );
  const survey = {
    id: "",
    questions: [
      {
        id: 1,
        title: `¿Cómo calificas la atención recibida por parte de ${surveyRecord.validatorName} que brindó la atención a tu solicitud con número de ticket ${surveyRecord.numberTicket}? *`,
      },
      {
        id: 2,
        title:
          "¿Recomendarías el uso de Mi Muro a otros compañeros de trabajo para realizar aclaraciones y/o actualizaciones a sus expedientes? *",
      },
      {
        id: 3,
        title:
          "¿Qué tanto esfuerzo tuviste que realizar para hacer tu aclaración y/o actualización de expediente en Mi Muro? *",
      },
      {
        id: 4,
        title:
          "Seleccione cuáles de los siguientes elementos le significaron mayor esfuerzo *",
      },
      {
        id: 5,
        title:
          "¿Tienes algún comentario que nos permita mejorar la atención a la solicitud de aclaraciones y/o actualizaciones de expedientes a través de Mi Muro?",
      },
    ],
  };
  const { errors, control, register, handleSubmit, watch } = useForm({
    defaultValues: surveyRecord,
  });
  const { answer_3 } = watch(["answer_3"]);

  useEffect(() => {
    if (answer_3 !== "1" && answer_3 !== "2") {
      setAnswer3a(utils.cloneObject(defaultAnswer3a));
    }
  }, [answer_3]);

  const renotifyNotification = async () => {
    const { notification_id } = surveyRecord;
    if (surveyRecord.times_notified < 2)
      await api.surveys.renotifyRecordSurvey(surveyRecord.dur_id, {
        notification_id,
      });
  };

  const closeModal = async () => {
    if (sendRenotify) {
      await renotifyNotification();
    }
    toggleOpen();
  };

  const handleRequestCloseSnackbar = () => {
    setOpenSnack(false);
  };

  const submit = async (data) => {
    setLoading(true);
    data.answer_3a = answer_3a || defaultAnswer3a;
    const response = await api.surveys.answerExpedienteSurvey(
      surveyRecord.dur_id,
      data
    );
    if (response.success) {
      if (callback) {
        callback();
      }
      toggleOpen(true);
    } else {
      setResponseMessage(response.message || DEFAULT_REQUEST_ERROR_MESSAGE);
      setOpenSnack(true);
    }
    setLoading(false);
  };

  return (
    <div>
      <Modal
        className="modal-box modal-box-mail SurveyRecordsModal"
        toggle={closeModal}
        isOpen={isOpen}
        style={{ zIndex: 2600 }}
        backdrop={"static"}
      >
        <ModalHeader className="modal-box-header text-white">
          <div style={{ maxWidth: "75%" }}>
            Encuesta de satisfacción - Mi expediente
          </div>
          <IconButton className="text-white" onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </ModalHeader>

        <form onSubmit={handleSubmit(submit)}>
          <div className="modal-box-content d-flex flex-column SurveyRecordsModal__Questions">
            <div className={"Question"}>
              <p className={"Question--title"}> {survey.questions[0].title}</p>
              <div className={"Question--options reactions"}>
                <FormControl>
                  <Controller
                    render={(props) => (
                      <RadioGroup
                        row
                        aria-label="answer_1"
                        defaultValue=""
                        {...props}
                      >
                        <FormControlLabel
                          value="1"
                          control={
                            <RadioSurveys
                              disabled={readOnly}
                              icon={
                                <IconButton>
                                  <i
                                    className={`icon-2-Mi-muro_Encuestas_resultados1`}
                                  />
                                </IconButton>
                              }
                              checkedIcon={
                                <IconButton>
                                  <i
                                    className={`icon-2-Mi-muro_Encuestas_resultados1 selected`}
                                  />
                                </IconButton>
                              }
                            />
                          }
                        />
                        <FormControlLabel
                          value="2"
                          control={
                            <RadioSurveys
                              disabled={readOnly}
                              icon={
                                <IconButton>
                                  <i
                                    className={`icon-2-Mi-muro_Encuestas_resultados2`}
                                  />
                                </IconButton>
                              }
                              checkedIcon={
                                <IconButton>
                                  <i
                                    className={`icon-2-Mi-muro_Encuestas_resultados2 selected`}
                                  />
                                </IconButton>
                              }
                            />
                          }
                        />
                        <FormControlLabel
                          value="3"
                          control={
                            <RadioSurveys
                              disabled={readOnly}
                              icon={
                                <IconButton>
                                  <i
                                    className={`icon-2-Mi-muro_Encuestas_resultados3`}
                                  />
                                </IconButton>
                              }
                              checkedIcon={
                                <IconButton>
                                  <i
                                    className={`icon-2-Mi-muro_Encuestas_resultados3 selected`}
                                  />
                                </IconButton>
                              }
                            />
                          }
                        />
                        <FormControlLabel
                          value="4"
                          control={
                            <RadioSurveys
                              disabled={readOnly}
                              icon={
                                <IconButton>
                                  <i
                                    className={`icon-2-Mi-muro_Encuestas_resultados4`}
                                  />
                                </IconButton>
                              }
                              checkedIcon={
                                <IconButton>
                                  <i
                                    className={`icon-2-Mi-muro_Encuestas_resultados4 selected`}
                                  />
                                </IconButton>
                              }
                            />
                          }
                        />
                      </RadioGroup>
                    )}
                    disabled={readOnly}
                    type="radio"
                    name="answer_1"
                    rules={{ required: REQUIRED }}
                    control={control}
                  />
                  {errors.answer_1 && (
                    <FormFeedback style={{ display: "block" }} type="invalid">
                      {errors.answer_1.message}
                    </FormFeedback>
                  )}
                </FormControl>
              </div>
            </div>
            <div className={"Question"}>
              <p className={"Question--title"}> {survey.questions[1].title}</p>
              <div className={"Question--radio-options row"}>
                <p
                  className="col-2 text-center"
                  style={{ "overflow-wrap": "break-word", padding: 0 }}
                >
                  Nunca lo recomendaría
                </p>
                <div
                  className="col-8"
                  style={{ display: "flex", "justify-content": "center" }}
                >
                  <FormControl>
                    <Controller
                      render={(props) => (
                        <RadioGroup
                          row
                          aria-label="answer_2"
                          className="RadioGroupCustomMyRecords"
                          defaultValue=""
                          {...props}
                        >
                          <FormControlLabel
                            disabled={readOnly}
                            className={"option"}
                            value="1"
                            control={<RadioSurveys />}
                            label="1"
                            labelPlacement="top"
                          />
                          <FormControlLabel
                            disabled={readOnly}
                            className={"option"}
                            value="2"
                            control={<RadioSurveys />}
                            label="2"
                            labelPlacement="top"
                          />
                          <FormControlLabel
                            disabled={readOnly}
                            className={"option"}
                            value="3"
                            control={<RadioSurveys />}
                            label="3"
                            labelPlacement="top"
                          />
                          <FormControlLabel
                            disabled={readOnly}
                            className={"option"}
                            value="4"
                            control={<RadioSurveys />}
                            label="4"
                            labelPlacement="top"
                          />
                          <FormControlLabel
                            disabled={readOnly}
                            className={"option"}
                            value="5"
                            control={<RadioSurveys />}
                            label="5"
                            labelPlacement="top"
                          />
                          <FormControlLabel
                            disabled={readOnly}
                            className={"option"}
                            value="6"
                            control={<RadioSurveys />}
                            label="6"
                            labelPlacement="top"
                          />
                          <FormControlLabel
                            disabled={readOnly}
                            className={"option"}
                            value="7"
                            control={<RadioSurveys />}
                            label="7"
                            labelPlacement="top"
                          />
                          <FormControlLabel
                            disabled={readOnly}
                            className={"option"}
                            value="8"
                            control={<RadioSurveys />}
                            label="8"
                            labelPlacement="top"
                          />
                          <FormControlLabel
                            disabled={readOnly}
                            className={"option"}
                            value="9"
                            control={<RadioSurveys />}
                            label="9"
                            labelPlacement="top"
                          />
                          <FormControlLabel
                            disabled={readOnly}
                            className={"option"}
                            value="10"
                            control={<RadioSurveys />}
                            label="10"
                            labelPlacement="top"
                          />
                        </RadioGroup>
                      )}
                      type="radio"
                      name="answer_2"
                      rules={{ required: REQUIRED }}
                      control={control}
                    />
                    {errors.answer_2 && (
                      <FormFeedback style={{ display: "block" }} type="invalid">
                        {errors.answer_2.message}
                      </FormFeedback>
                    )}
                  </FormControl>
                </div>
                <p
                  className="col-2 text-center"
                  style={{ "overflow-wrap": "break-word", padding: 0 }}
                >
                  Totalmente lo voy a recomendar
                </p>
              </div>
            </div>
            <div className={"Question"}>
              <p className={"Question--title"}> {survey.questions[2].title}</p>
              <div className={"Question--radio-options"}>
                <FormControl>
                  <Controller
                    render={(props) => (
                      <RadioGroup
                        aria-label="answer_3"
                        defaultValue=""
                        {...props}
                      >
                        <FormControlLabel
                          disabled={readOnly}
                          value="1"
                          control={<RadioSurveys />}
                          label="Me costó mucho trabajo"
                        />
                        <FormControlLabel
                          disabled={readOnly}
                          value="2"
                          control={<RadioSurveys />}
                          label="Tuve que esforzarme"
                        />
                        <FormControlLabel
                          disabled={readOnly}
                          value="3"
                          control={<RadioSurveys />}
                          label="Neutro (ni mucho, ni poco)"
                        />
                        <FormControlLabel
                          disabled={readOnly}
                          value="4"
                          control={<RadioSurveys />}
                          label="Fue sencillo"
                        />
                        <FormControlLabel
                          disabled={readOnly}
                          value="5"
                          control={<RadioSurveys />}
                          label="Fue muy fácil de hacer."
                        />
                      </RadioGroup>
                    )}
                    type="radio"
                    name="answer_3"
                    rules={{ required: REQUIRED }}
                    control={control}
                  />
                  {errors.answer_3 && (
                    <FormFeedback style={{ display: "block" }} type="invalid">
                      {errors.answer_3.message}
                    </FormFeedback>
                  )}
                </FormControl>
              </div>
            </div>
            {(answer_3 === "1" || answer_3 === "2") && (
              <div className={"Question"}>
                <p className={"Question--title"}>
                  {" "}
                  {survey.questions[3].title}
                </p>
                <FormGroup>
                  <Controller
                    render={({ onChange }) => {
                      const handleChange = (evt) => {
                        answer_3a[evt.target.name] =
                          evt.target.name === "7"
                            ? evt.target.value
                            : evt.target.checked;
                        onChange(utils.cloneObject(answer_3a));
                        setAnswer3a(answer_3a);
                      };
                      return (
                        <>
                          <FormControlLabel
                            control={<CbxSurveys />}
                            disabled={readOnly}
                            onChange={handleChange}
                            checked={answer_3a[0]}
                            name="0"
                            label="Acceso a Internet"
                          />
                          <FormControlLabel
                            control={<CbxSurveys />}
                            disabled={readOnly}
                            onChange={handleChange}
                            checked={answer_3a[1]}
                            name="1"
                            label="Ingreso a Mi Muro"
                          />
                          <FormControlLabel
                            control={<CbxSurveys />}
                            disabled={readOnly}
                            onChange={handleChange}
                            checked={answer_3a[2]}
                            name="2"
                            label="Uso de Mi Muro"
                          />
                          <FormControlLabel
                            control={<CbxSurveys />}
                            disabled={readOnly}
                            onChange={handleChange}
                            checked={answer_3a[3]}
                            name="3"
                            label="Solicitud de mi aclaración y/o actualización"
                          />
                          <FormControlLabel
                            control={<CbxSurveys />}
                            disabled={readOnly}
                            onChange={handleChange}
                            checked={answer_3a[4]}
                            name="4"
                            label="Seguimiento a la solicitud de mi expediente"
                          />
                          <FormControlLabel
                            control={<CbxSurveys />}
                            disabled={readOnly}
                            onChange={handleChange}
                            checked={answer_3a[5]}
                            name="5"
                            label="Comunicación con la o el validador de mi expediente"
                          />
                          <span>
                            <FormControlLabel
                              control={<CbxSurveys />}
                              disabled={readOnly}
                              onChange={handleChange}
                              checked={answer_3a[6]}
                              name="6"
                              label="Otro:"
                            />
                            {answer_3a[6] && (
                              <TextField
                                spellCheck
                                onChange={handleChange}
                                inputProps={{ readOnly: readOnly }}
                                value={answer_3a[7]}
                                name="7"
                                style={{ width: "87%" }}
                              />
                            )}
                          </span>
                        </>
                      );
                    }}
                    type="checkbox"
                    name="answer_3a"
                    control={control}
                  />
                </FormGroup>
              </div>
            )}
            <div className={"Question"}>
              <p className={"Question--title"}> {survey.questions[4].title}</p>
              <TextField
                spellCheck
                name="answer_4"
                placeholder={"Tu respuesta"}
                inputProps={{ readOnly: readOnly }}
                inputRef={register}
                fullWidth
              />
            </div>
          </div>

          {!readOnly && (
            <div className="modal-box-footer">
              {loading ? (
                <div className="text-center w-100">
                  <CircularProgress />
                </div>
              ) : (
                <div className="container">
                  <div className="row">
                    <div className="p-0 text-center">
                      <Button
                        type="submit"
                        variant="contained"
                        size="small"
                        className="mi_muro-btn_cyan"
                      >
                        Enviar encuesta
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </form>
      </Modal>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={15000}
        open={openSnack}
        onClose={handleRequestCloseSnackbar}
        ContentProps={{
          "aria-describedby": "message-id",
          className: "bg-danger",
        }}
        message={<span id="message-id">{responseMessage}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleRequestCloseSnackbar}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </div>
  );
};

SurveyRecordsModal.propTypes = {
  toggleOpen: PropTypes.func,
  callback: PropTypes.func,
  isOpen: PropTypes.bool,
  readOnly: PropTypes.bool,
  surveyRecord: PropTypes.object,
  sendRenotify: PropTypes.bool,
};

export default SurveyRecordsModal;
