import React from "react";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Modal, ModalHeader } from "reactstrap";

const RolesList = props => {
  const { handleDialogClose, open, data = [] } = props;

  return (
    <Modal
      className="modal-box modal-box-mail AddAdendumModal"
      toggle={handleDialogClose}
      isOpen={open}
      style={{ zIndex: 2600 }}
    >
      <ModalHeader
        className="modal-box-header text-white p-1 pl-5 ModalHeader"
      >
        <div style={{ maxWidth: "75%" }}>
          Roles
        </div>
        <IconButton className="text-gray" onClick={handleDialogClose}>
          <CloseIcon />
        </IconButton>
      </ModalHeader>
      <div className="modal-box-content d-flex flex-column">
        {data}
      </div>
    </Modal>
  );
}

export default RolesList;