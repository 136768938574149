import React, { useEffect, useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import {
  IconButton,
  CircularProgress,
  Button,
  Snackbar,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Field from "./Field";
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "constants/Messages";
import api from "services/api";
import "../../styles.scss";

const AnnouncementForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [survey, setSurvey] = useState([]);
  const [state, setState] = useState({});
  const [disabled, setDisabled] = useState(true);

  const fetchSurvey = async () => {
    setLoading(true);
    const { announcement_id } = props;
    const response = await api.surveysAnnouncements.getSurvey(announcement_id);

    if (response.success) {
      const { survey } = response;
      const newSurvey = Object.keys(survey).map((key) => ({
        ...survey[key],
        propName: key,
      }));
      setSurvey(newSurvey);
    } else {
      setMessage(response.message || DEFAULT_REQUEST_ERROR_MESSAGE);
      setOpenSnack(true);
    }

    setLoading(false);
  };

  const saveSurvey = async () => {
    setLoading(true);

    const { announcement_id } = props;
    const data = new FormData();
    data.append("survey_announcement_id", announcement_id);
    survey.forEach(({ propName, value }) => {
      if (typeof value === "object") {
        data.append(propName, JSON.stringify(value));
      } else {
        data.append(propName, value);
      }
    });

    const response = await api.surveysAnnouncements.saveSurvey({
      announcement_id: announcement_id,
      data,
    });

    if (response.success) {
      props.toggleOpen(true);
    } else {
      setMessage(response.message || DEFAULT_REQUEST_ERROR_MESSAGE);
      setOpenSnack(true);
    }

    setLoading(false);
  };

  const validateField = ({ propName, value }) => {
    const isContent = (val) => {
      return typeof val === "string" && val.length > 0;
    };

    const validateCheckboxData = () => {
      if (typeof value !== "object" || value === null) {
        return false;
      }

      if (value.hasOwnProperty("other")) {
        const val = value.other;
        if (val !== false && !isContent(val)) {
          return false;
        }
        if (isContent(val)) return true;
      }

      const { other, ...valuesWithoutOther } = value;

      for (const key in valuesWithoutOther) {
        if (value[key] === true) {
          return true;
        }
      }

      return false;
    };

    switch (propName) {
      case "question_1":
        return typeof value === "number" && value > 0;

      case "question_3a":
        return validateCheckboxData();

      default:
        return isContent(value);
    }
  };

  const validateForm = (snapshotState = state) => {
    for (const field of survey) {
      const { value, propName } = field;

      if (propName === 'question_3a') {
        if (snapshotState.question_3 === '1' || snapshotState.question_3 === '2') {
          if (validateField({ propName, value }) !== true) {
            setDisabled(true);
            return false;
          }
        }
      } else {
        if (propName != "question_4" && validateField({ propName, value }) !== true) {
          setDisabled(true);
          return false;
        }
      }

    }
    setDisabled(false);
    return true;
  };

  const clearCheckboxValue = () => {
    const idx = survey.findIndex(question => question.propName === 'question_3a');
    if (idx > -1 && idx < survey.length) {
      survey[idx].value = null;
    }
  }

  const updateState = (idx, value) => {
    const { propName } = survey[idx];
    const snapshotState = { ...state };
    let newState = {};

    if (propName === 'question_3') {
      if (value === '1' || value === '2') {
        newState = { ...snapshotState, question_3: value };
      } else {
        const { question_3a, ...stateWithoutQuestio3a } = snapshotState;
        newState = { ...stateWithoutQuestio3a, question_3: value };
        clearCheckboxValue();
      }
    } else {
      newState = { ...snapshotState, [propName]: value };
    }
    setState(newState);
    return newState;
  }

  const onUpdateValue = (idx, value) => {
    survey[idx].value = value;
    validateForm(updateState(idx, value));
  };

  useEffect(() => {
    fetchSurvey();
  }, []);

  const { toggleOpen, isOpen } = props;

  return (
    <div>
      <Modal
        className="modal-box modal-box-mail AnnouncementFormModal"
        toggle={toggleOpen}
        isOpen={isOpen}
        style={{ zIndex: 2600 }}
        backdrop={"static"}
      >
        <ModalHeader className="modal-box-header text-white">
          <div style={{ maxWidth: "75%" }}>
            Encuesta de satisfacción - Convocatorias
          </div>
          <IconButton className="text-white" onClick={toggleOpen}>
            <CloseIcon />
          </IconButton>
        </ModalHeader>

        <div className="modal-box-content d-flex flex-column">
          {survey.map((field, idx) => (
            <Field
              key={idx}
              data={field}
              handleInputChange={(val) => onUpdateValue(idx, val)}
              state={state}
            />
          ))}
        </div>

        <div className="modal-box-footer">
          {loading ? (
            <div className="text-center w-100">
              <CircularProgress />
            </div>
          ) : (
            <div className="container">
              <div className="row">
                <div className="p-0">
                  <Button
                    variant="contained"
                    onClick={saveSurvey}
                    size="small"
                    disabled={loading || disabled}
                    className="mi_muro-btn_cyan"
                  >
                    Enviar encuesta
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={15000}
        open={openSnack}
        onClose={() => setOpenSnack(false)}
        ContentProps={{
          "aria-describedby": "message-id",
          className: "bg-danger",
        }}
        message={<span id="message-id">{message}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => setOpenSnack(false)}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </div>
  );
};

export default AnnouncementForm;
