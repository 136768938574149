export const REQUIRED = "Este campo es requerido";
export const DATE = "Se debe ingresar una fecha válida";
export const NUMBER = "Debe ingresar un número válido";
export const INTEGER = "El número no puede contener decimales";
export const EMAIL = "Correo electrónico inválido. Ejemplo: ejemplo@email.com";
export const PERCENTAGE = "Valor inválido. Ejemplo: 50 o 50.01";
export const CELLPHONE =
  "Número de celular inválido. Ejemplo: +52 33 3333 3333";
export const PHONE = "Número de teléfono inválido. Ejemplo: 33 3333 3333";
export const NO_NEG = "Debe ingresar un valor positivo";
export const MIN_LENGTH = length =>
  `Este campo debe contener al menos ${length} caracteres`;
export const MAX_LENGTH = length =>
  `Este campo no debe contener mas de ${length} caracteres`;
export const MIN = min => `El valor mínimo es ${min}`;
export const MAX = max => `El valor máximo es ${max}`;
export const MONEY = "El valor ingresado no es válido";
export const PASSWORD =
  "Contraseña inválida. Al menos 6 caracteres, 1 letra mayúscula, 1 minúscula, 1 número, 1 símbolo";
export const PASSWORDMATCH = "Las contraseñas deben ser iguales";

export const FILESIZE = "Los archivos no deben exceder los 5MB en tamaño";
export const FILE_NOT_ALLOWED = "Tipo de archivo no permitido";
export const FILESIZENOTI = "Los archivos no deben exceder los 20MB en tamaño";
export const FILENOTALLOW = "Tipo de archivo no permitido";
