import { sendRequest } from "util/utils";
import storage from "services/storage";

const list = async (page, term, undersecretary, workArea,areaManagement ) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/work_groups?page=${page}&term=${term}&work_area=${workArea}&undersecretary=${undersecretary}&area_management=${areaManagement}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const getWorkGroup = async id => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/work_groups/${id}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const modifyWorkGroupUsers = async (id, data) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/work_groups/${id}/collaborators`,
    request = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
      body: JSON.stringify(data)
    };
  return await sendRequest(route, request);
};

const createWorkGroup = async data => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/work_groups`;
  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
      token: storage.getSSID(),
    },
    body: JSON.stringify(data)
  };
  return await sendRequest(route, request);
};

const deleteWorkGroup = async id => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/work_groups/${id}`,
    request = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const createActivity = async (id, data) => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/activities/${id}`;
  const request = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: token,
      token: storage.getSSID(),
    },
    body: data
  };
  return await sendRequest(route, request);
};

const getActivities = async id => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/activities/${id}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const updateProcedureRequest = async (id, procedure) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/work_groups/requests/${id}`,
    request = {
      method: "POST",
      headers: {
        Authorization: token,
        token: storage.getSSID(),
      },
      body: procedure
    };
  return await sendRequest(route, request);
};

const getWorkGroupsBySearch = async (term, page) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/search-workGroups?term=${term}&page=${page}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

const sendAppointmentNotification = async (procedure_request_id) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/work_groups/${procedure_request_id}/appointment`,
    request = {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      }
    };
  return await sendRequest(route, request);
};

export default {
  list,
  getWorkGroup,
  deleteWorkGroup,
  modifyWorkGroupUsers,
  createWorkGroup,
  createActivity,
  getActivities,
  updateProcedureRequest,
  getWorkGroupsBySearch,
  sendAppointmentNotification
};
