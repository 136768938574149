import numeral from "numeral";
import baseUrl from "../constants/Api";
import storage from "../services/storage";
import validators from "./validators";
import React from "react";
import * as Yup from "yup";
import * as questionTypes from "constants/QuestionTypes";

const extensions = {
  image: ["jpg", "jpeg", "png", "jpej", "gif"],
  pdf: ["pdf"],
  document: ["xlsx", "docx", "pptx", "xml"],
  audio: ["mp3", "mp4", "mov"],
};

const months = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dic",
];

const formatTableDate = (date) => {
  const newDate = new Date(date);
  let day = newDate.getDate().toString();
  if (day.length === 1) day = "0" + day;
  return `${day}-${months[newDate.getMonth()]}-${newDate.getFullYear()}`;
};

// const generateInputName = (index) => `dynamicInput${index + 1}`

const generateYupSchema = (inputs) => {
  const shape = {};
  inputs.forEach(
    ({
      validations = {},
      matrixValidations = {},
      hasValidations,
      id,
      type,
      ...question
    }) => {
      if (
        type === "multipleChoice" ||
        type === "dropDown" ||
        type === "time" ||
        !validations.dataType
      ) {
        validations.dataType = "string";
      }
      let validator = Yup[
        validations.dataType === "number" ? "string" : validations.dataType
      ]();
      if (validations.dataType === "number") {
        validator =
          validations["validationType"] === "decimal"
            ? validator.test(
                id,
                "Solo se permiten números decimales 0.00",
                (value) => {
                  return value === "" || /^\d*(\.\d+)?$/.test(value);
                }
              )
            : validator.test(id, "Solo se permiten números", (value) => {
                return /^\d*$/.test(value);
              });
      }
      if (validations.dataType === "date") {
        validator = validator
          .nullable()
          .transform((curr, orig) => (orig === "" ? null : curr))
          .typeError("Selecciona una fecha válida");
      }
      if (questionTypes.file === type) {
        if (validations.required) {
          validator = validator.test(id, "Campo requerido", (value) => {
            return value.length;
          });
        }
        if (question.maxFiles) {
          const maxFiles = parseInt(question.maxFiles);
          validator = validator.test(
            id,
            `El número máximo de archivos es ${maxFiles}`,
            (value) => {
              return value.length <= maxFiles;
            }
          );
        }
        if (question.maxFileSize) {
          const maxFileSize = question.maxFileSize * 1048576;
          validator = validator.test(
            id,
            `El tamáño máximo por archivo es de ${question.maxFileSize}mb`,
            (value) => {
              let fileSizesAreCorrect = true;
              value.forEach((file) => {
                if (file.size > maxFileSize) {
                  fileSizesAreCorrect = false;
                }
              });
              return fileSizesAreCorrect;
            }
          );
        }
        let acceptableExtensions = [];
        if (question.allowSomeFiles) {
          for (let fileType in question.allowedFiles) {
            if (question.allowedFiles[fileType]) {
              acceptableExtensions = acceptableExtensions.concat(
                extensions[fileType]
              );
            }
          }
        } else {
          for (let fileType in extensions) {
            acceptableExtensions = acceptableExtensions.concat(
              extensions[fileType]
            );
          }  
        }
        validator = validator.test(
          id,
          `Hay un archivo de tipo no permitido`,
          (value) => {
            let fileFormatsAreCorrect = true;
            value.forEach((file) => {
              const fileName = file.name.split(".");
              const extension = fileName[fileName.length - 1].toLowerCase();
              if (!acceptableExtensions.includes(extension)) {
                fileFormatsAreCorrect = false;
              }
            });
            return fileFormatsAreCorrect;
          }
        );
      }
      if (hasValidations) {
        if (type === questionTypes.matrixTxt) {
          let matrixShape = {};
          for (let field in matrixValidations) {
            const fieldValidations = matrixValidations[field];
            let subValidator = Yup[fieldValidations.dataType || "string"]();
            for (let key in fieldValidations) {
              if (validations.required) {
                subValidator = subValidator.required(validations.required);
              }
              if (key === "format" && fieldValidations[key]) {
                subValidator = subValidator[fieldValidations[key]](
                  `El texto debe ser un ${fieldValidations[key]}`
                );
              } else if (
                fieldValidations[key] &&
                key !== "dataType" &&
                key !== "validationType"
              ) {
                let message = "";
                if (
                  key === "min" &&
                  fieldValidations.dataType === "string" &&
                  fieldValidations[key]
                ) {
                  message = `El texto debe tener mínimo ${fieldValidations[key]} caracteres`;
                }
                if (
                  key === "max" &&
                  fieldValidations.dataType === "string" &&
                  fieldValidations[key]
                ) {
                  message = `El texto debe tener máximo ${fieldValidations[key]} caracteres`;
                }
                if (subValidator[key]) {
                  subValidator = subValidator[key](
                    fieldValidations[key],
                    message
                  );
                }
              } else if (
                key === "dataType" &&
                fieldValidations[key] === "number"
              ) {
                subValidator = subValidator.typeError(
                  "El contenido debe ser un número"
                );
              }
            }
            matrixShape[field] = subValidator;
          }
          validator = validator.shape(matrixShape);
        } else {
          for (let key in validations) {
            if (key === "format" && validations[key] && type === "shortAnswer") {
              validator = validator[validations[key]](
                `El texto debe ser un ${validations[key]}`
              );
            } else if (key === "required" && validations.required) {
              validator = validator.required(validations.required);
            } else if (
              validations[key] &&
              key !== "dataType" &&
              key !== "validationType"
            ) {
              let message = "";
              if (
                key === "min" &&
                (validations.dataType === "string" ||
                  validations.dataType === "number") &&
                validations[key]
              ) {
                message = `El texto debe tener mínimo ${validations[key]} caracteres`;
              }
              if (
                key === "max" &&
                (validations.dataType === "string" ||
                  validations.dataType === "number") &&
                validations[key]
              ) {
                message = `El texto debe tener máximo ${validations[key]} caracteres`;
              }
              if (
                key === "min" &&
                validations.dataType === "array" &&
                validations[key]
              ) {
                message = `Debes escoger mínimo ${validations[key] &&
                  validations[key]} respuesta(s)`;
              }
              if (
                key === "max" &&
                validations.dataType === "array" &&
                validations[key]
              ) {
                message = `Debes escoger máximo ${validations[key]} respuesta(s)`;
              }
              if (key === "length" && validations[key]) {
                message = `El número de respuestas debe ser de ${validations[key]}`;
              }
              if (type === questionTypes.cbx && key === "length") {
                validator = validator.test(id, message, (value) => {
                  const realValue = value.filter((v) => !!v);
                  return realValue.length == validations[key];
                });
              } else if (validator[key] && validations.dataType === "string") {
                if (key === "min") {
                  validator = validator.test(id, message, (value) => {
                    return value === "" || value.length >= validations[key];
                  });
                }
                if (key === "max") {
                  validator = validator.test(id, message, (value) => {
                    return value === "" || value.length <= validations[key];
                  });
                }
              } else if (validator[key] && validations.dataType === "number") {
                if (key === "min") {
                  message = !!validations.max
                    ? `Solo se permiten números, los caracteres permitidos como mínimo son ${validations.min} y como máximo ${validations.max}`
                    : `Solo se permiten números, los caracteres permitidos como mínimo son ${validations[key]}`;
                  validator = validator.test(id, message, (value) => {
                    return value === "" || value.length >= validations[key];
                  });
                }
                if (key === "max") {
                  message = !!validations.min
                    ? `Solo se permiten números, los caracteres permitidos como mínimo son ${validations.min} y como máximo ${validations.max}`
                    : `Solo se permiten números, los caracteres permitidos como máximo son ${validations[key]}`;
                  validator = validator.test(id, message, (value) => {
                    return value === "" || value.length <= validations[key];
                  });
                }
                if (key === "length") {
                  message = `Solo se permiten números, los caracteres exactos permitidos son ${validations[key]}`;
                  validator = validator.test(id, message, (value) => {
                    return value === "" || value.length == validations[key];
                  });
                }
              } else if (validator[key] && validations.dataType === "array") {
                if (key === "min") {
                  validator = validator.test(id, message, (value) => {
                    const realValue = value.filter((v) => !!v);
                    return realValue.length >= validations[key];
                  });
                }
                if (key === "max") {
                  validator = validator.test(id, message, (value) => {
                    const realValue = value.filter((v) => !!v);
                    return realValue.length <= validations[key];
                  });
                }
              } else if (validator[key]) {
                validator = validator[key](validations[key], message);
              }
            }
          }
        }
      } else if (validations.required && question.type !== "file") {
        validator = validator.required(validations.required);
      }
      shape[id] = validator;
    }
  );
  return Yup.object().shape(shape);
};

export const cloneObject = (object) => {
  return JSON.parse(JSON.stringify(object));
};

const getParsedDate = (date) => {
  const dateArray = date.split("/");
  const year = dateArray[2];
  const month = parseInt(dateArray[1], 10) - 1;
  const day = dateArray[0];
  const _entryDate = new Date(year, month, day);
  return _entryDate;
};

const parseDate = (date, fillWithZero = false) => {
  let dateArray = [];
  let day = date.getDate();
  if (fillWithZero && day <= 9) {
    day = "0"+day;
  }
  let month = date.getMonth() + 1;
  if (fillWithZero && month <= 9) {
    month = "0"+month;
  }
  dateArray.push(day);
  dateArray.push(month);
  dateArray.push(date.getFullYear());
  const dateString = dateArray.join("/");
  return dateString;
};

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const sendRequest = async (
  route,
  request,
  isFile = false,
  refresh = true
) => {
  let response;
  try {
    response = await fetch(baseUrl + route, request);
    if (response.status === 401) {
      return refresh
        ? await refreshToken(route, request, isFile)
        : { success: false };
    }
  } catch (error) {
    return { success: false };
  }
  try {
    const data =
      isFile && response.status === 200
        ? await response.blob()
        : await response.json();
    return data;
  } catch (error) {
    return { success: false };
  }
};

const awaitRefreshToken = async (route, request, isFile) => {
  let response;
  while (storage.getFetchingToken()) {
    await sleep(100);
  }
  try {
    const newRequest = {
      ...request,
      headers: {
        ...request.headers,
        Authorization: `Bearer ${storage.getToken()}`,
      },
    };
    response = await fetch(baseUrl + route, newRequest);
    const data = isFile ? await response.blob() : await response.json();
    return data;
  } catch (error) {
    return { success: false };
  }
};

const refreshToken = async (route, request, isFile) => {
  let response;
  if (storage.getFetchingToken()) {
    return awaitRefreshToken(route, request, isFile);
  }
  storage.setFetchingToken(true);
  try {
    response = await fetch(baseUrl + "/refreshToken", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        token: storage.getSSID(),
      },
      body: JSON.stringify({ refresh_token: storage.getRefreshToken() }),
    });
    response = await response.json();
    if (response.success) {
      storage.updateToken(response.token);
      const newRequest = {
        ...request,
        headers: {
          ...request.headers,
          Authorization: `Bearer ${storage.getToken()}`,
        },
      };
      response = await fetch(baseUrl + route, newRequest);
      const data = isFile ? await response.blob() : await response.json();
      return data;
    } else {
      storage.removeToken();
      window.location.reload();
      return {
        success: false,
        logout: true,
      };
    }
  } catch (error) {
    storage.removeToken();
    return {
      success: false,
      logout: true,
    };
  }
};

const formatMoney = (number) => {
  const myNumeral = numeral(number);
  return myNumeral.format("$0,0.00");
};

const formatDecimals = (number) => {
  const myNumeral = numeral(number);
  return myNumeral.format("0,0.00");
};

const formatDateToString = (date) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: "UTC",
  };
  return new Date(date).toLocaleDateString("es-MX", options);
};

const getFormattedDate = (date, symbol = "/") => {
  return `${date.getDate()}${symbol}${date.getMonth() +
    1}${symbol}${date.getFullYear()}`;
};

const addDateAndFormat = (date, years = 0, months = 0, days = 0) => {
  const dateObj = new Date(date);
  dateObj.setFullYear(dateObj.getFullYear() + years);
  dateObj.setMonth(dateObj.getMonth() + months);
  dateObj.setDate(dateObj.getDate() + days);

  return getFormattedDate(dateObj);
};

const formatStringToDate = (date) => {
  let d = date.split("/");
  return `${d[1]}/${d[0]}/${d[2]}`;
};

/**
 * Recibe la cantidad y el tipo y retorna una cadena de la forma '5 litros' o '300 pesos'
 *
 * @param {number} amount
 * @param {string} type ['lt', 'money']
 */
const formatCostString = (amount, type) => {
  let costString = `${amount} `;
  costString += type === "lt" ? "litros" : "pesos";
  return costString;
};

const getSelectItem = (item, options) => {
  options.some((option) => {
    if (option.value === item) {
      item = option;
      return true;
    }
    return false;
  });
  return item;
};

//Obtener los items para un select multiple
const getSelectItems = (items, options) => {
  const selectedItems = [];
  items = items || [];
  options.forEach((option) => {
    if (items.includes(option.value)) {
      selectedItems.push(option);
    }
  });
  return selectedItems;
};

const getLabelOptionFromValue = (item, options) => {
  options.some((option) => {
    if (option.value === item) {
      item = option.label;
      return true;
    }
    return false;
  });
  return item;
};

const getColorByStatus = (status) => {
  switch (status) {
    case "Activo":
      return "#59c74f";
    case "Concluido negativamente":
      return "#d44629";
    case "Concluido positivamente":
      return "#537ea2";
    case "Concluido positivamente con invierte":
      return "#d2a7fe";
    case "Concluido positivamente sin seguimiento":
      return "#e56520";
    default:
      return "gray";
  }
};

const getStylesByType = (type) => {
  var obj = {};
  switch (type) {
    case "Cambio en proyecto":
      obj = {
        color: "#9991d4",
        icon: (
          <>
            <path
              fill-rule="evenodd"
              d="M8 15A7 7 0 108 1a7 7 0 000 14zm0 1A8 8 0 108 0a8 8 0 000 16z"
              clip-rule="evenodd"
            />
            <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z" />
            <circle cx="8" cy="4.5" r="1" />
          </>
        ),
      };
      break;
    case "Creación de proyecto":
      obj = {
        color: "#948ee3",
        icon: (
          <>
            <path
              fill-rule="evenodd"
              d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"
            />
            <path
              fill-rule="evenodd"
              d="M9.5 1h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"
            />
          </>
        ),
      };
      break;
    case "SLA":
      obj = {
        color: "#bbb7ed",
        icon: (
          <>
            <path d="M8 16a2 2 0 002-2H6a2 2 0 002 2z" />
            <path
              fill-rule="evenodd"
              d="M8 1.918l-.797.161A4.002 4.002 0 004 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 00-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 111.99 0A5.002 5.002 0 0113 6c0 .88.32 4.2 1.22 6z"
              clip-rule="evenodd"
            />
          </>
        ),
      };
      break;
    case "Correo entrante":
      obj = {
        color: "#a8a3e8",
        icon: (
          <>
            <path
              fill-rule="evenodd"
              d="M5.854 5.646a.5.5 0 010 .708L3.207 9l2.647 2.646a.5.5 0 01-.708.708l-3-3a.5.5 0 010-.708l3-3a.5.5 0 01.708 0z"
              clip-rule="evenodd"
            />
            <path
              fill-rule="evenodd"
              d="M13.5 2.5a.5.5 0 01.5.5v4a2.5 2.5 0 01-2.5 2.5H3a.5.5 0 010-1h8.5A1.5 1.5 0 0013 7V3a.5.5 0 01.5-.5z"
              clip-rule="evenodd"
            />
          </>
        ),
      };
      break;
    case "Correo recibido":
      obj = {
        color: "#a8a3e8",
        icon: (
          <>
            <path
              fill-rule="evenodd"
              d="M5.854 5.646a.5.5 0 010 .708L3.207 9l2.647 2.646a.5.5 0 01-.708.708l-3-3a.5.5 0 010-.708l3-3a.5.5 0 01.708 0z"
              clip-rule="evenodd"
            />
            <path
              fill-rule="evenodd"
              d="M13.5 2.5a.5.5 0 01.5.5v4a2.5 2.5 0 01-2.5 2.5H3a.5.5 0 010-1h8.5A1.5 1.5 0 0013 7V3a.5.5 0 01.5-.5z"
              clip-rule="evenodd"
            />
          </>
        ),
      };
      break;
    case "Correo enviado":
      obj = {
        color: "#a8a3e8",
        icon: (
          <>
            <path
              fill-rule="evenodd"
              d="M9.896 2.396a.5.5 0 000 .708l2.647 2.646-2.647 2.646a.5.5 0 10.708.708l3-3a.5.5 0 000-.708l-3-3a.5.5 0 00-.708 0z"
              clip-rule="evenodd"
            />
            <path
              fill-rule="evenodd"
              d="M13.25 5.75a.5.5 0 00-.5-.5h-6.5a2.5 2.5 0 00-2.5 2.5v5.5a.5.5 0 001 0v-5.5a1.5 1.5 0 011.5-1.5h6.5a.5.5 0 00.5-.5z"
              clip-rule="evenodd"
            />
          </>
        ),
      };
      break;
    case "Nota interna":
      obj = {
        color: "#b7b4e0",
        icon: (
          <>
            <path
              fill-rule="evenodd"
              d="M14.5 3h-13a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"
            />
            <path
              fill-rule="evenodd"
              d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"
            />
          </>
        ),
      };
      break;
    default:
      obj = {
        color: "#948ee3",
        icon: (
          <>
            <path
              fill-rule="evenodd"
              d="M7.879 11.015a.5.5 0 0 1 .242 0l6 1.5a.5.5 0 0 1 .037.96l-6 2a.499.499 0 0 1-.316 0l-6-2a.5.5 0 0 1 .037-.96l6-1.5z"
            />
            <path d="M11.885 12.538l-.72-2.877C10.303 9.873 9.201 10 8 10s-2.303-.127-3.165-.339l-.72 2.877c-.073.292-.002.6.256.756C4.86 13.589 5.916 14 8 14s3.14-.411 3.63-.706c.257-.155.328-.464.255-.756zM9.97 4.88l.953 3.811C10.159 8.878 9.14 9 8 9c-1.14 0-2.159-.122-2.923-.309L6.03 4.88C6.635 4.957 7.3 5 8 5s1.365-.043 1.97-.12zm-.245-.978L8.97.88C8.718-.13 7.282-.13 7.03.88L6.275 3.9C6.8 3.965 7.382 4 8 4c.618 0 1.2-.036 1.725-.098z" />
          </>
        ),
      };
      break;
  }
  return obj;
};

const getIconByFiletype = (type) => {
  switch (type) {
    case "png":
      return <i className="zmdi zmdi-image-o" />;
    case "jpeg":
      return <i className="zmdi zmdi-image-o" />;
    case "docsx":
      return <i className="zmdi zmdi-file-text" />;
    case "pdf":
      return <i className="zmdi zmdi-collection-pdf" />;
    case "pptx":
      return <i className="zmdi zmdi-airplay" />;
    default:
      return <i className="zmdi zmdi-attachment-alt" />;
  }
};

const isValidPhone = (number = "", cellphone = false) => {
  number = number.replace(/\s/g, "");
  if (number === "") {
    return true;
  }
  let phoneRegex;
  if (cellphone) {
    phoneRegex = /^\+?([0-9]{2})([0-9]{10})$/;
  } else {
    phoneRegex = /^([0-9]{10})$/;
  }
  return phoneRegex.test(number);
};

const monthDiff = (dateFrom, dateTo) => {
  return (
    dateTo.getMonth() -
    dateFrom.getMonth() +
    12 * (dateTo.getFullYear() - dateFrom.getFullYear())
  );
};

const getUrlParams = (key) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key);
};

//Genera un ID unico a partir de la fecha
export const generateUUID = () => {
  var d = new Date().getTime();
  var uuid = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};
const formatTime = (time) => {
  var timeString = time;
  var H = +timeString.substr(0, 2);
  var h = H % 12 || 12;
  var ampm = H < 12 || H === 24 ? "AM" : "PM";
  timeString = h + timeString.substr(2, 3) + ampm;
  return timeString;
};

export default {
  cloneObject,
  formatMoney,
  formatDecimals,
  formatDateToString,
  formatStringToDate,
  getFormattedDate,
  addDateAndFormat,
  formatCostString,
  getSelectItem,
  getSelectItems,
  getStylesByType,
  getLabelOptionFromValue,
  getColorByStatus,
  isValidPhone,
  getIconByFiletype,
  validators,
  monthDiff,
  getUrlParams,
  getParsedDate,
  parseDate,
  generateYupSchema,
  generateUUID,
  formatTableDate,
  formatTime,
};
