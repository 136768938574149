import React from "react";
import Avatar from "@material-ui/core/Avatar";
import DisplayDate from "components/wall/DisplayDate";
import { icons } from "constants/Notifications";
import { connect } from "react-redux";
import {
  handleSurveyProcedureModal,
  handleSurveyAnnouncementModal,
  handleSurveyRecordModal
} from "actions";
import { makeStyles } from "@material-ui/core/styles";
import api from 'services/api';
import Chip from "components/Chip"

const useStyles = makeStyles({
  notificationIcon: {
    height: "40px",
    width: "fit-content",
    "font-size": "16px",
    "border-radius": "30px",
    display: "flex",
    "justify-content": "flex-end",
    "align-items": "flex-start",
    "margin-right": "10px",
  },
});

const NotificationItem = ({
  notification,
  history,
  deleteNotification,
  handleSurveyAnnouncementModal,
  handleSurveyRecordModal,
                            handleSurveyProcedureModal
}) => {
  const types = icons;
  const {
    id,
    user_photography,
    user_photography_custom,
    title,
    description,
    type,
    date_released,
    url,
  } = notification;
  const classes = useStyles();

  const showExpedienteSurveyModal = async (id, notification_id) => {
    const response = await api.surveys.getSurveyExpedienteInfo(id);
    if (response.success) {
      response.data.dur_id = id;
      response.data.notification_id = notification_id;
      response.data.validatorName = response.data.validator;
      response.data.numberTicket = response.data.ticket;
      handleSurveyRecordModal({open: true, renotify: true, data: response.data});
    }
  }

  const clickNotification = async () => {
    deleteNotification(id);
    //survey_modal/procedure/:id
    //survey_modal/record/:id
    //survey_modal/annoucement/:id

    if (typeof url === 'string' && url.includes('survey_modal')) {
      const parts = url.split("/")
      const survey_id = parts[2];
      switch (parts[1]) {
        case 'procedure':
          handleSurveyProcedureModal({open: true, id:survey_id, mode: 'answer'});
          break;

        case 'announcement':
          handleSurveyAnnouncementModal({open: true, id: survey_id});
          break;

        case 'record':
          showExpedienteSurveyModal(survey_id, id);
          break;
        default:
          break;
      }
      return;
    }

    history.push(!!url ? url : `/app/notifications/view/${id}/received`);
  };
  // showExpedienteSurveyModal(1)
  const buildNotImg = () => {
    switch (type) {
      case "schedule_appointment":
        return (
          <div className={`${classes.notificationIcon}`}>
            <i className="zmdi zmdi-calendar"></i>
          </div>
        );

      case "schedule_appointment_set":
        return (
          <div className={`${classes.notificationIcon}`}>
            <i className="zmdi zmdi-calendar"></i>
          </div>
        );

      case "new_member":
        return (
          <div className={`${classes.notificationIcon}`}>
            <i className="zmdi zmdi-account-add"></i>
          </div>
        );

      case 31:
        return (
          <div className={`${classes.notificationIcon}`}>
            <i
              className={`zmdi zmdi icon-SEJ_Menu-tramites_Mensaje zmdi-hc-fw`}
              style={{
                color: `${types[type].color}`,
                fontSize: "0.8rem",
                alignSelf: "center",
              }}
            />
          </div>
        );

      default:
        return (
          <div className={`${classes.notificationIcon}`}>
            <i
              style={{ color: `${types[type] ? types[type].color : "grey"}` }}
              className={`zmdi iconNotif-Mi-muro_Notificaciones_${types[type] ? types[type].icon : "avisos"
                } 
               zmdi-check-circle`}
            ></i>
          </div>
        );
    }
  };

  return (
    <li
      className="media"
      onClick={clickNotification}
      style={{ cursor: "pointer" }}
    >
      {user_photography_custom || user_photography ? (
        <Avatar
          alt="user"
          src={user_photography_custom || user_photography}
          className=" mr-2"
        />
      ) : (
        buildNotImg()
      )}
      <div className="media-body align-self-center">
        <p className="notifications-bell__text mb-0">
          {type === 1 ? title : description}
        </p>
        <div className="row">
          <div className="col-sm-6 col-md-7 col-12">
            <span className="meta-date">
              <i
                style={{ color: "#989898" }}
                className={`zmdi zmdi-time zmdi-hc-fw`}
              />
            </span>
            <span className="meta-date">
              <small>
                {` `}
                <DisplayDate date={date_released} />
              </small>
            </span>
          </div>
          {
            type === 33 &&
            <div className="col-sm-6 col-md-5 col-12">
              <Chip variant="info">SICSSE</Chip>
            </div>
          }
        </div>
      </div>
    </li>
  );
};

export default connect(null, { handleSurveyProcedureModal, handleSurveyAnnouncementModal, handleSurveyRecordModal })(NotificationItem);

