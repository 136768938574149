import React, { useState } from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import '../../styles.scss';

const RadioColumnInput = props => {
  const [value, setValue] = useState(null);

  const onChangeRadio = ev => {
    const { handleChange } = props;
    const { value: val } = ev.target;
    handleChange(val);
    setValue(val);
  }

  const { input = {} } = props;
  const { options = [] } = input;

  return <div className="AnnounFormRadioField">
    <FormControl component="fieldset">
      <RadioGroup
        className="AFRadioGroup"
        value={value}
        onChange={onChangeRadio}
      >
        {options.map(({ value: val, label }, idx) => (
          <FormControlLabel
            key={idx}
            value={String(val)}
            control={<Radio />}
            label={label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  </div>;
}

export default RadioColumnInput;