
export const shortAnswer = 'shortAnswer'
export const multipleChoice = 'multipleChoice'
export const largeAnswer = 'largeAnswer'
export const cbx = 'cbx'
export const dropDown = 'dropDown'
export const date = 'date'
export const time = 'time'
export const file = 'file'
export const linealScale = 'linealScale'
export const matrixValues = 'matrixValues'
export const matrixCbx = 'matrixCbx'
export const matrixTxt = 'matrixTxt'
export const cct = 'cct'
export const cct_fill = 'cct_fill'
export const sgu = 'sgu'
export const sgu_fill = 'sgu_fill'