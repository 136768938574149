export const renderAllowedFiles = (allowedFiles) => {
    const fileLabels = {
        image: "Imagen",
        pdf: "PDF",
        document: "Documento",
        audio: "Audio o vídeo",
    };
    if (allowedFiles && Object.keys(allowedFiles).length) {
        return Object.keys(allowedFiles).map((key => fileLabels[key])).join(", ");
    }
    return "Documento, PDF, Imagen, Audio o Vídeo";
}