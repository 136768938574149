import { sendRequest } from "util/utils";
import storage from "services/storage";

const getAbsenceDays = async () => {
  const route = "/absence-days";
  const request = {
    method: "GET",
    headers: {
      Accept: "application/json",
      token: storage.getSSID(),
    },
  };
  return await sendRequest(route, request);
};

const updateDays = async (data) => {
  const route = "/absence-days/reasign-dates";
  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      token: storage.getSSID(),
    },
    body: JSON.stringify(data),
  };
  return await sendRequest(route, request);
}

export default {
  getAbsenceDays,
  updateDays,
};
