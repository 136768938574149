import storage from "services/storage";
import { sendRequest } from "util/utils";

const getSurvey = async (announcement_id) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/survey-announcement/questions/${announcement_id}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

const saveSurvey = async ({ data, announcement_id }) => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/survey-announcement/answer/${announcement_id}`;
  const request = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: token,
      token: storage.getSSID(),
    },
    body: data,
  };
  return await sendRequest(route, request);
};

const getAnswers = async ({ survey_id, user_id }) => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/survey-announcement/answer/${survey_id}/${user_id}`;
  const request = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: token,
      token: storage.getSSID(),
    },
  };
  return await sendRequest(route, request);
};

const getSurveysList = async () => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/survey-announcement`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

const getUsersBySurvey = async (user_id) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/survey-announcement/${user_id}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

const searchAnnouncemnts = async ({ search, startDate, page }) => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/survey-announcements/search?search=${search}&start_date=${startDate}&page=${page}`;
  const request = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: token,
      token: storage.getSSID(),
    },
  };
  return await sendRequest(route, request);
}

const searchUsers = async ({search, survey_id, page}) => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/survey-announcements/search/users/${survey_id}?search=${search}&page=${page}`;
  const request = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: token,
      token: storage.getSSID(),
    },
  };
  return await sendRequest(route, request);
}

const getReport = async ({survey_id, format}) => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/survey-announcements/report/${survey_id}/${format}`;
  const request = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: token,
      token: storage.getSSID(),
    },
  };
  return await sendRequest(route, request);
}

export default {
  getSurvey,
  saveSurvey,
  getAnswers,
  getSurveysList,
  getUsersBySurvey,
  searchAnnouncemnts,
  searchUsers,
  getReport,
};
