import React from "react";
import { TextField } from "@material-ui/core";
import '../../styles.scss';

const TextInput = ({handleChange}) => {
    return (
        <TextField
            id="description"
            label="Tu respuesta"
            className="InputForm"
            onChange={ev => handleChange(ev.target.value)}
            margin="normal"
            fullWidth
            spellCheck="true"
        />
    );
}

export default TextInput;