import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IntlMessages from "util/IntlMessages";
import storage from "services/storage";
import RolesList from "./RolesList";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import UserImageModal from "app/routes/Profile/Components/UserImageModal";
import api from "services/api";

class UserInfo extends React.Component {
  state = {
    anchorEl: null,
    open: false,
    openRoles: false,
    changeImgOpen: false,
  };

  handleClick = (event) => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  handleChangeImgToggle = () => {
    const { changeImgOpen } = this.state;
    this.setState({ changeImgOpen: !changeImgOpen });
  };

  goToProfile = () => {
    this.props.history.push("/app/profile");
    this.handleRequestClose();
  };

  goToRequests = () => {
    this.props.history.push("/app/procedures/my-procedures");
    this.handleRequestClose();
  };

  logout = () => {
    api.sgu.logout();
    storage.removeSSID();
    storage.removeToken();
    if (window.FreshworksWidget) {
      window.FreshworksWidget("identify", "ticketForm", {
        name: "",
        email: "",
      });
    }
    window.location.href = "https://mi.sej.jalisco.gob.mx/?servicio=https://mimuro.jalisco.gob.mx";
    this.setState({ open: false });
  };

  roles = () => {
    this.setState({ open: false, openRoles: true });
  };

  closeRoles = () => {
    this.setState({ openRoles: false });
  };

  render() {
    const data = storage.getData();
    const name = data?.user?.name || "Usuario sin nombre";
    const imgSrc =
      data?.user?.photography_custom ||
      data?.user?.photography ||
      "https://via.placeholder.com/150x150";
    const { openRoles, changeImgOpen } = this.state;

    return (
      <>
        {openRoles && (
          <RolesList
            handleDialogClose={this.closeRoles.bind(this)}
            open={openRoles}
            data={data?.user?.rol || ""}
          />
        )}
        <div className="ml-2 user-profile d-flex flex-row align-items-center">
          <div className="header-user-info">
            <div className="user-detail d-flex flex-row align-items-center header-rol-name">
              <h4 className="user-name mr-2 ml-2" style={{ color: "white" }}>
                {name}
              </h4>
            </div>
            <Avatar
              alt="..."
              src={imgSrc}
              className="user-avatar size-25 pointer"
              style={{ backgroundColor: "white" }}
              onClick={this.handleChangeImgToggle}
            />
            <ExpandMoreIcon
              style={{ cursor: "pointer" }}
              onClick={this.handleClick}
            />
          </div>

          <Menu
            className="user-info"
            id="simple-menu"
            anchorEl={this.state.anchorEl}
            open={this.state.open}
            onClose={this.handleRequestClose}
            PaperProps={{
              style: {
                minWidth: 150,
                paddingTop: 0,
                paddingBottom: 0,
                marginTop: "30px",
                borderRadius: "20px",
              },
            }}
          >
            <MenuItem
              onClick={() => {
                this.goToProfile();
              }}
            >
              <IntlMessages id="popup.profile" />
            </MenuItem>

            <MenuItem
              onClick={() => {
                this.goToRequests();
              }}
            >
              <IntlMessages id="popup.requests" />
            </MenuItem>

            <MenuItem
              onClick={() => {
                this.roles();
              }}
            >
              <IntlMessages id="popup.roles" />
            </MenuItem>

            <MenuItem
              onClick={() => {
                this.logout();
              }}
            >
              <IntlMessages id="popup.logout" />
            </MenuItem>
          </Menu>
        </div>

        {changeImgOpen && (
          <UserImageModal
            open={changeImgOpen}
            handleDialogClose={this.handleChangeImgToggle.bind(this)}
          />
        )}
      </>
    );
  }
}

export default UserInfo;
