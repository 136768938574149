import React, { useState } from "react";
import '../../styles.scss';

const ReactionInput = ({ input, handleChange }) => {
  const [reaction, setReaction] = useState(null);

  const changeReaction = (value) => {
    if (reaction === value) { return; }

    handleChange(value);
    setReaction(value);
  }

  return <div className="AnnounFormReactionField">
    {input.option.map(({ value }, idx) => (
      <i key={idx}
        className={`zmdi icon-2-Mi-muro_Encuestas_resultados${value}
          ${reaction === value ? 'ReactionSelected' : ''}`}
        onClick={() => changeReaction(value)}
      />
    ))}
  </div>;
}

export default ReactionInput;