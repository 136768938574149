import React from "react";
import PropTypes from "prop-types";
import "./styles.css"

const Chip = React.forwardRef(function Chip(props, ref) {
  const { children, variant } = props;

  return (
    <div {...props} ref={ref} className={`chip ${variant}`}>
      <div className="chip-highlight"></div>
      <div className="px-3">
        { children }
      </div>
    </div>
  );
});

Chip.propTypes = {
  variant: PropTypes.oneOf(["success", "danger", "error", "disabled", "info"]),
}

Chip.defaultProps = {
  variant: "success",
};

export default Chip;