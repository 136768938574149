import { sendRequest } from "util/utils";
import storage from "services/storage";

const login = async () => {
  const route = "/login";
  const request = {
    method: "POST",
    headers: {
      Accept: "application/json",
      token: storage.getSSID(),
    },
  };
  return await sendRequest(route, request);
};

const logout = async () => {
  const route = "/logout";
  const request = {
    method: "POST",
    headers: {
      Accept: "application/json",
      token: storage.getSSID(),
    },
  };
  return await sendRequest(route, request);
};

export default {
  login,
  logout
};
