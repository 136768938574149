import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import "./styles.scss";

export function VideoContainer(props) {
  const { url, externalUrl } = props;
  const rand = Math.random();
  const videoUrl = `${url}?t=${rand}`;

  return (
      externalUrl
      ? <iframe src={externalUrl} allowFullScreen className="tutorial_external_video" />
      : <video
        controls
        width={"90%"}
        src={videoUrl}
      />    
  );
}

const VideoModal = (props) => {
  const { isOpen, onClose, file, externalUrl } = props;

  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
    >
      <ModalHeader toggle={onClose}>
        Vídeo
      </ModalHeader>
      <ModalBody>
        {
          externalUrl
          ? <iframe src={externalUrl} allowFullScreen style={{minWidth: "640px", minHeight: "480px"}} />
          : <video src={file} width="100%" controls />
        }
      </ModalBody>
    </Modal>
  );
}

export default VideoModal;
