import storage from "services/storage";
import { sendRequest } from "util/utils";

const getSurveyListSocial = async (page) => {
    const token = `Bearer ${storage.getToken()}`,
      route = `/survey?page=${page}`,
      request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: token,
          token: storage.getSSID(),
        },
      };
    return await sendRequest(route, request);
  };

const getSurveySocialSearch = async (search, filter, page) => {
    const token = `Bearer ${storage.getToken()}`,
      route = `/surveys/search?search=${search}&filter=${filter}&page=${page}`,
      request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: token,
          token: storage.getSSID(),
        },
      };
    return await sendRequest(route, request);
  };

const getSurveySocialDetail = async (id, page, term) => {
    const token = `Bearer ${storage.getToken()}`,
      route = `/survey/${id}?page=${page}&term=${term}`,
      request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: token,
          token: storage.getSSID(),
        },
      };
    return await sendRequest(route, request);
  };

const getSurveySocialDetailAnswers = async (survey_id, userId) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/survey/answers/${survey_id}/${userId}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

const getQuestionsSocial = async (survey_id, userId) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/survey/questions/${survey_id}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

const createSurvey = async (data) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/survey`,
    request = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },      
      body: data,
    };
  return await sendRequest(route, request);
};

const answerSurveySocialMedia = async (survey_id, data) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/survey/answer/${survey_id}`,
    request = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
        "Content-Type": "application/json",
      },
      body: data,
    };
  return await sendRequest(route, request);
};

const downloadSurveyReportSocial = async (id, format) => {
    const token = `Bearer ${storage.getToken()}`,
      route = `/survey/report/${id}/${format}`,
      request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: token,
          token: storage.getSSID(),
        },
      };
    return await sendRequest(route, request, true);
};

const answerExpedienteSurvey = async (id, data) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/surveys/records/answer-survey/${id}`,
    request = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
      body: JSON.stringify(data),
    };
  return await sendRequest(route, request);
}

const getSurveyListExpediente = async (page, term, start_date, end_date) => {
  const token = `Bearer ${storage.getToken()}`,
      route = `/surveys/records?page=${page}&term=${term}&start_date=${start_date}&end_date=${end_date}`,
      request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: token,
          token: storage.getSSID(),
        },
      };
    return await sendRequest(route, request);
}

const getSurveyExpedienteInfo = async (recordSurvey) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/surveys/records/getDurInfo/${recordSurvey}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
}

const renotifyRecordSurvey = async (id, data) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/surveys/records/renotify/${id}`,
    request = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
      body: JSON.stringify(data)
    };
  return await sendRequest(route, request);
}

const downloadSurveyReportExpediente = async (format, term, startDate, endDate) => {
  const token = `Bearer ${storage.getToken()}`,
    // route = `/surveys/records/report?format=${format}&term=${term}&start_date=${startDate}&end_date=${endDate}`,
    route = `/surveys/records/report?format=${format}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request, true);
}

const getLastDateSurveySocial = async () => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/date/surveys`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        token: storage.getSSID(),
      },
    };
  return await sendRequest(route, request);
};

export default {
    getSurveyListSocial,
    getSurveySocialDetail,
    getSurveySocialSearch,
    getSurveySocialDetailAnswers,
    createSurvey,
    downloadSurveyReportSocial,
    answerExpedienteSurvey,
    getSurveyListExpediente,
    getSurveyExpedienteInfo,
    renotifyRecordSurvey,
    downloadSurveyReportExpediente,
    answerSurveySocialMedia,
    getQuestionsSocial,
    getLastDateSurveySocial,
}