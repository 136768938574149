import React, { PureComponent } from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import "assets/vendors/style";
import defaultTheme from "./themes/defaultTheme";
import AppLocale from "../lngProvider";
import { setInitUrl } from "../actions";
import MainApp from "app/index";
import RTL from "util/RTL";
import storage from "services/storage";
import asyncComponent from "util/asyncComponent";
import LoginSSID from "containers/LoginSSID";
import QRData from "containers/QRData";
import api from "services/api"
// import Directory from "../components/DeskHelp";

const RestrictedRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login-ssid",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const LoginSSIDRedirector = ({
  component: Component,
  ssid,
  route,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      ssid ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: route,
            state: { from: props.location.pathname },
          }}
        />
      )
    }
  />
);

class App extends PureComponent {
  constructor() {
    super();
    this.setState({
      loading: true
    });
  }

  componentDidMount() {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (this.props.initURL === "") {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
    if (!this.searchSSID() && !/^\/appointments\/read\/qr/.test(this.props.location.pathname)) {
      window.location.href = "https://mi.sej.jalisco.gob.mx/?servicio=https://mimuro.jalisco.gob.mx";
    }
    this.validateToken();
  }

  searchSSID() {
    return !!storage.getSSID();
  }

  async validateToken() {
    const token = storage.getToken();
    if (token) {
      await api.user.getCurrentUser();
    }
    this.setState({
      loading: false
    });
  }

  render() {
    const {
      match,
      location,
      locale,
      isDirectionRTL,
    } = this.props;

    if (location.pathname === "/") {
      return <Redirect to={"/app/blog"} />;
    }
    const applyTheme = createMuiTheme(defaultTheme);

    if (isDirectionRTL) {
      applyTheme.direction = "rtl";
      document.body.classList.add("rtl");
    } else {
      document.body.classList.remove("rtl");
      applyTheme.direction = "ltr";
    }
    const token = storage.getToken();
    const ssid = storage.getSSID();
    const route = "/app/blog";
    const currentAppLocale = AppLocale[locale.locale];
    if (this.state?.loading) {
      return null;
    }
    return (
      <MuiThemeProvider theme={applyTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
          >
            <RTL>
              <div className="app-main">
                <Switch>
                  <RestrictedRoute
                    path={`${match.url}app`}
                    authUser={token}
                    component={MainApp}
                  />
                  <LoginSSIDRedirector
                    path="/login-ssid"
                    component={LoginSSID}
                    ssid={ssid}
                    route={route}
                    exact
                  />
                  <Route path="/appointments/read/qr/:id" component={QRData} />
                  <Route
                    component={asyncComponent(() =>
                      import("components/Error404")
                    )}
                  />
                </Switch>
                {/* {
                  !location.pathname.includes('/qr') &&
                  <Directory />
                } */}
              </div>
            </RTL>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { sideNavColor, locale, isDirectionRTL } = settings;
  return { sideNavColor, locale, isDirectionRTL };
};
export default connect(mapStateToProps, { setInitUrl })(App);
